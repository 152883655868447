#page-wrapper{
    min-height: 100vh;
    #page{
        padding-bottom: 44px; 
        min-height: 100vh;
        position:relative;
        footer.site-footer{
            position: absolute;
            bottom: 0;
            width: 100vw;

            .region-footer{
                margin:0;
                #block-footer { 
                    width: 100%;
                    background-color: rgba($grey-dark,.95);
                    color:$white;

                    @include media-breakpoint-down(xs) {
                        padding: 0px 30px;   
                    }

                    ul {
                        @include media-breakpoint-down(xs) {
                            display: none !important;
                        }
                        li {
                            &:first-child {

                            }
                        }
                    }

                    a, p{
                        color: $white;
                        @include font-size(13);
                    }
                    .text-formatted{
                        @extend .container;
                        justify-content: space-between;
                        align-items: center;
                        display:flex;
                        padding: 10px 0;

                        @include media-breakpoint-down(sm) {
                            padding: 10px 25px;
                        }

                        @include media-breakpoint-down(xs) {
                            padding: 15px 0;
                            flex-direction: column;
                            p {
                                text-align: center;
                                &:nth-child(2){ order:3; }
                                &:nth-child(3){ order:2; }
                            }
                        }

                        & > *{
                            width: 100%;
                            display: block;
                            margin: 0;
                        }
                        & > p {
                            text-align: center;
                        }
                        & > p:last-of-type{
                            text-align: right !important;
                            text-transform: uppercase;

                            @include media-breakpoint-down(xs) {
                                text-align: center !important;
                                margin-top: 6px;
                            }
                        }
                        & > ul{
                            display: flex;
                            padding: 0;
                            list-style-type: none;
                            li{
                                padding-right: 30px;

                                @include media-breakpoint-down(sm) {
                                    padding-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


