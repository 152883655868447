// Checkbox
.custom_checkbox{
    opacity: 0;
}
.custom_checkbox + label{
    padding-left: 30px !important;
}
.node-form{
    .custom_checkbox{
        opacity: 1;
    }
    
    .custom_checkbox + label{
        padding-left: 0px !important;
    }
}
.custom_checkbox:not(:checked) + label:before,
.custom_checkbox:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid #aaa;
    background: #FFF;
    border-radius: .2em;
    transition: all .275s;
}

/* Aspect de la coche */
.custom_checkbox:not(:checked) + label:after,
.custom_checkbox:checked + label:after {
    content: '✓';
    position: absolute;
    top: .65em;
    left: .23em;
    font-size: 1.1em;
    color: $blue;
    line-height: 0;
    transition: all .2s; /* Petite transition */
}

/* Aspect non cochée */
.custom_checkbox:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0) rotate(45deg);
}

/* Aspect cochée */
.custom_checkbox:checked + label:after {
    opacity: 1;
    transform: scale(1) rotate(0);
}
/* Disabled checkbox */
.custom_checkbox:disabled:not(:checked) + label:before,
.custom_checkbox:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #e9e9e9;
}

/* Disabled checked */
.custom_checkbox:disabled:checked + label:after {
    color: #777;
}

.custom_checkbox:disabled + label {
    color: #aaa;
}