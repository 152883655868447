/* TYPOGRAPHY */

$base-font-size: 16px;

// V3 : google font
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;400;500;700&display=swap");

// V2
/*@include font-face('HelveticaNeueThin', '../assets/fonts/HelveticaNeue-Thin');
@include font-face('HelveticaNeueLight', '../assets/fonts/HelveticaNeue-Light');
@include font-face('HelveticaNeueRegular', '../assets/fonts/HelveticaNeue');
@include font-face('HelveticaNeueMedium', '../assets/fonts/HelveticaNeue-Medium');
@include font-face('HelveticaNeueBold', '../assets/fonts/HelveticaNeue-Bold');
@include font-face('HelveticaNeueHeavy', '../assets/fonts/HelveticaNeue-Heavy');*/
/*$hT: 'HelveticaNeueThin',Arial,Verdana,sans-serif;
$hL: 'HelveticaNeueLight',Arial,Verdana,sans-serif;
$hR: 'HelveticaNeueRegular',Arial,Verdana,sans-serif;
$hM: 'HelveticaNeueMedium',Arial,Verdana,sans-serif;
$hB: 'HelveticaNeueHeavy',Arial,Verdana,sans-serif;
$hSB: 'HelveticaNeueBold',Arial,Verdana,sans-serif;*/

// V 1
/*@include font-face('openSansR', '../assets/fonts/OpenSans-Regular');
@include font-face('openSansB', '../assets/fonts/OpenSans-Bold');
@include font-face('openSansSB', '../assets/fonts/OpenSans-SemiBold');
@include font-face('openSansI', '../assets/fonts/OpenSans-Italic');*/
/*$openR: 'openSansR',Arial,Verdana,sans-serif; 
$openB: 'openSansB',Arial,Verdana,sans-serif;
$openSB: 'openSansSB',Arial,Verdana,sans-serif;
$openI: 'openSansI',Arial,Verdana,sans-serif;*/

//CEPHEID SANS FONT
@include font-face('CepheidSansBoldItalic', '../assets/fonts/CepheidSans/CepheidSans-Bold-Italic');
@include font-face('CepheidSansBold', '../assets/fonts/CepheidSans/CepheidSans-Bold');
@include font-face('CepheidSansItalic', '../assets/fonts/CepheidSans/CepheidSans-Italic');
@include font-face('CepheidSansLight-Italic', '../assets/fonts/CepheidSans/CepheidSans-Light-Italic');
@include font-face('CepheidSansLight', '../assets/fonts/CepheidSans/CepheidSans-Light');
@include font-face('CepheidSansRegular', '../assets/fonts/CepheidSans/CepheidSans-Regular');
@include font-face('CepheidSansSemibold-Italic', '../assets/fonts/CepheidSans/CepheidSans-Semibold-Italic');
@include font-face('CepheidSansSemibold', '../assets/fonts/CepheidSans/CepheidSans-Semibold');

// CEPHEID first then Helvetica
$h: HelveticaNeue,Heebo,Arial,Verdana,sans-serif; // Thin = 100, Regular = 400, SB = 500, B = 700
$font-legacy: $h;
$font-thin: CepheidSansLight, HelveticaNeue,Heebo,Arial,Verdana,sans-serif; // Thin = 100, Regular = 400, SB = 500, B = 700
$font-bold: CepheidSansBold, HelveticaNeue,Heebo,Arial,Verdana,sans-serif; // Thin = 100, Regular = 400, SB = 500, B = 700
$font-semibold: CepheidSansSemibold, HelveticaNeue,Heebo,Arial,Verdana,sans-serif; // Thin = 100, Regular = 400, SB = 500, B = 700
$font-regular: CepheidSansRegular, HelveticaNeue,Heebo,Arial,Verdana,sans-serif; // Thin = 100, Regular = 400, SB = 500, B = 700

$font-default: $font-regular;

@font-face {
	font-family: 'icon';
  src:  url('../assets/fonts/icons/icomoon.eot?ggttxe');
  src:  url('../assets/fonts/icons/icomoon.eot?ggttxe#iefix') format('embedded-opentype'),
  url('../assets/fonts/icons/icomoon.ttf?ggttxe') format('truetype'),
  url('../assets/fonts/icons/icomoon.woff?ggttxe') format('woff'),
  url('../assets/fonts/icons/icomoon.svg?ggttxe#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-aide .path1:before {
  content: "\e900";
  color: rgb(251, 184, 0);
}
.icon-aide .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-back-website:before {
  content: "\e902";
  color: #fff;
}
.icon-blue-download .path1:before {
  content: "\e903";
  color: rgb(0, 122, 194);
}
.icon-blue-download .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-blue-download .path3:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-blue-search:before {
  content: "\e906";
  color: #007ac2;
}
.icon-blue-video:before {
  content: "\e907";
  color: #007ac2;
}
.icon-burger-close:before {
  content: "\e908";
  color: #fff;
}
.icon-burger-open:before {
  content: "\e909";
  color: #fff;
}
.icon-calendar:before {
  content: "\e90a";
  color: #4d4d4f;
}
.icon-cheked:before {
  content: "\e90b";
  color: #007ac2;
}
.icon-close-modale:before {
  content: "\e90c";
  color: #fff;
}
.icon-close-popup .path1:before {
  content: "\e90d";
  color: rgb(112, 112, 112);
}
.icon-close-popup .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(77, 77, 79);
}
.icon-close-popup .path3:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(77, 77, 79);
}
.icon-close-reset .path1:before {
  content: "\e910";
  color: rgb(215, 216, 214);
}
.icon-close-reset .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(34, 34, 34);
}
.icon-close-reset .path3:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(34, 34, 34);
}
.icon-close-search:before {
  content: "\e913";
  color: #a2a2a2;
}
.icon-delete:before {
  content: "\e914";
  color: #007ac2;
}
.icon-edit:before {
  content: "\e915";
  color: #007ac3;
}
.icon-external-link-square:before {
  content: "\e916";
  color: #4d4d4f;
}
.icon-eyes:before {
  content: "\e917";
  color: #4d4d4f;
}
.icon-faq:before {
  content: "\e918";
  color: #fff;
}
.icon-filtre:before {
  content: "\e919";
  color: #484949;
}
.icon-fleche-input:before {
  content: "\e91a";
  color: #4d4d4f;
}
.icon-fleche-top:before {
  content: "\e91b";
  color: #fdb71a;
}
.icon-fleche .path1:before {
  content: "\e91c";
  color: rgb(0, 122, 195);
  opacity: 0.236;
}
.icon-fleche .path2:before {
  content: "\e91d";
  margin-left: -0.9111328125em;
  color: rgb(0, 122, 195);
  opacity: 0.236;
}
.icon-fleche .path3:before {
  content: "\e91e";
  margin-left: -0.9111328125em;
  color: rgb(0, 122, 195);
}
.icon-fleche .path4:before {
  content: "\e91f";
  margin-left: -0.9111328125em;
  color: rgb(0, 122, 195);
}
.icon-green-faq:before {
  content: "\e920";
  color: #77b800;
}
.icon-green-faq1:before {
  content: "\e921";
  color: #77b800;
}
.icon-green-less .path1:before {
  content: "\e922";
  color: rgb(119, 184, 0);
}
.icon-green-less .path2:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(119, 184, 0);
  opacity: 0.219;
}
.icon-green-message:before {
  content: "\e924";
  color: #77b800;
}
.icon-green-profile:before {
  content: "\e925";
  color: #77b800;
}
.icon-green-subscribe:before {
  content: "\e926";
  color: #77b800;
}
.icon-icon:before {
  content: "\e927";
  color: #77b800;
}
.icon-info:before {
  content: "\e928";
  color: #bec0bd;
}
.icon-log-out:before {
  content: "\e929";
  color: #fff;
}
.icon-loupe:before {
  content: "\e92a";
  color: #fff;
}
.icon-mail:before {
  content: "\e92b";
  color: #fff;
}
.icon-manage-search:before {
  content: "\e92c";
  color: #fff;
}
.icon-paper:before {
  content: "\e92d";
  color: #fff;
}
.icon-parametres:before {
  content: "\e92e";
  color: #fff;
}
.icon-log-in:before {
  content: "\e92f";
  color: #fff;
}


html {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	font-weight: 400;
}
@include media-breakpoint-down(sm) {
	html {
		font-size: 0.9rem;
	}
}
/**
 * Firefox specific rule
 */
@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}