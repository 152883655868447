@charset "UTF-8";
/* IMPORTS */
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;400;500;700&display=swap");
.transition, #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .nav-item a, #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .nav-item a:before {
  transition: all 0.3s ease-in-out; }

/* MIXINS */
:root {
  --blue: #007AC3;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #77B800;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007AC3;
  --secondary: #6c757d;
  --success: #77B800;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007AC3;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #004a77;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.img-fluid, .media-left .media .embedded-entity img, .media-left .media p img, .media-left .media img img, .media-right .media .embedded-entity img, .media-right .media p img, .media-right .media img img {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container, #page-wrapper #main-wrapper #content, #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted, #custom-search-cepheid .container_wrapper .advanced .container-advanced, #wrapper_content_selector #edit-output {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container, #page-wrapper #main-wrapper #content, #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted, #custom-search-cepheid .container_wrapper .advanced .container-advanced, #wrapper_content_selector #edit-output {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, #page-wrapper #main-wrapper #content, #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted, #custom-search-cepheid .container_wrapper .advanced .container-advanced, #wrapper_content_selector #edit-output {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, #page-wrapper #main-wrapper #content, #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted, #custom-search-cepheid .container_wrapper .advanced .container-advanced, #wrapper_content_selector #edit-output {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container, #page-wrapper #main-wrapper #content, #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted, #custom-search-cepheid .container_wrapper .advanced .container-advanced, #wrapper_content_selector #edit-output {
      max-width: 1140px; } }
  @media (min-width: 1400px) {
    .container, #page-wrapper #main-wrapper #content, #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted, #custom-search-cepheid .container_wrapper .advanced .container-advanced, #wrapper_content_selector #edit-output {
      max-width: 1320px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, #page-wrapper #main-wrapper #content, #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted, #custom-search-cepheid .container_wrapper .advanced .container-advanced, #wrapper_content_selector #edit-output, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, #page-wrapper #main-wrapper #content, #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted, #custom-search-cepheid .container_wrapper .advanced .container-advanced, #wrapper_content_selector #edit-output, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, #page-wrapper #main-wrapper #content, #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted, #custom-search-cepheid .container_wrapper .advanced .container-advanced, #wrapper_content_selector #edit-output, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, #page-wrapper #main-wrapper #content, #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted, #custom-search-cepheid .container_wrapper .advanced .container-advanced, #wrapper_content_selector #edit-output, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, #page-wrapper #main-wrapper #content, #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted, #custom-search-cepheid .container_wrapper .advanced .container-advanced, #wrapper_content_selector #edit-output, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .alert-wrapper .alert, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, #block-alertinformation, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4, .alert-wrapper .alert {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4, .alert-wrapper .alert {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8, #block-alertinformation {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2, #block-alertinformation {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6, #block-alertinformation {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6, #block-alertinformation {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5, #block-alertinformation {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7, #block-alertinformation {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: #44b9ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 122, 195, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #77B800; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(119, 184, 0, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #77B800;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2377B800' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #77B800;
    box-shadow: 0 0 0 0.2rem rgba(119, 184, 0, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #77B800;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2377B800' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #77B800;
    box-shadow: 0 0 0 0.2rem rgba(119, 184, 0, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #77B800; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #77B800; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #77B800; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #98eb00;
  background-color: #98eb00; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(119, 184, 0, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #77B800; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #77B800; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #77B800;
  box-shadow: 0 0 0 0.2rem rgba(119, 184, 0, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #007AC3; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff;
    border-color: #007AC3;
    background-color: #007AC3; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 122, 195, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #44b9ff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #77ccff;
    border-color: #77ccff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #ffffff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007AC3;
  background-color: #007AC3; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 122, 195, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 122, 195, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 122, 195, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #ffffff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 122, 195, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #44b9ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 122, 195, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #ffffff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #44b9ff;
    box-shadow: 0 0 0 0.2rem rgba(0, 122, 195, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 122, 195, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 122, 195, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 122, 195, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007AC3;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #77ccff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007AC3;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #77ccff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007AC3;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #77ccff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #007AC3; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container, .navbar #page-wrapper #main-wrapper #content, #page-wrapper #main-wrapper .navbar #content, .navbar #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list, #block-views-block-homepage-document-list-block-1 .content > div .navbar .view-homepage-document-list, .navbar #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted, #page-wrapper #page footer.site-footer .region-footer #block-footer .navbar .text-formatted, .navbar #custom-search-cepheid .container_wrapper .advanced .container-advanced, #custom-search-cepheid .container_wrapper .advanced .navbar .container-advanced, .navbar #wrapper_content_selector #edit-output, #wrapper_content_selector .navbar #edit-output,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl, .navbar .container-xxl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, #page-wrapper #main-wrapper .navbar-expand-sm > #content, #block-views-block-homepage-document-list-block-1 .content > div .navbar-expand-sm > .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .navbar-expand-sm > .text-formatted, #custom-search-cepheid .container_wrapper .advanced .navbar-expand-sm > .container-advanced, #wrapper_content_selector .navbar-expand-sm > #edit-output,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container, #page-wrapper #main-wrapper .navbar-expand-sm > #content, #block-views-block-homepage-document-list-block-1 .content > div .navbar-expand-sm > .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .navbar-expand-sm > .text-formatted, #custom-search-cepheid .container_wrapper .advanced .navbar-expand-sm > .container-advanced, #wrapper_content_selector .navbar-expand-sm > #edit-output,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, #page-wrapper #main-wrapper .navbar-expand-md > #content, #block-views-block-homepage-document-list-block-1 .content > div .navbar-expand-md > .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .navbar-expand-md > .text-formatted, #custom-search-cepheid .container_wrapper .advanced .navbar-expand-md > .container-advanced, #wrapper_content_selector .navbar-expand-md > #edit-output,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container, #page-wrapper #main-wrapper .navbar-expand-md > #content, #block-views-block-homepage-document-list-block-1 .content > div .navbar-expand-md > .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .navbar-expand-md > .text-formatted, #custom-search-cepheid .container_wrapper .advanced .navbar-expand-md > .container-advanced, #wrapper_content_selector .navbar-expand-md > #edit-output,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, #page-wrapper #main-wrapper .navbar-expand-lg > #content, #block-views-block-homepage-document-list-block-1 .content > div .navbar-expand-lg > .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .navbar-expand-lg > .text-formatted, #custom-search-cepheid .container_wrapper .advanced .navbar-expand-lg > .container-advanced, #wrapper_content_selector .navbar-expand-lg > #edit-output,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container, #page-wrapper #main-wrapper .navbar-expand-lg > #content, #block-views-block-homepage-document-list-block-1 .content > div .navbar-expand-lg > .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .navbar-expand-lg > .text-formatted, #custom-search-cepheid .container_wrapper .advanced .navbar-expand-lg > .container-advanced, #wrapper_content_selector .navbar-expand-lg > #edit-output,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, #page-wrapper #main-wrapper .navbar-expand-xl > #content, #block-views-block-homepage-document-list-block-1 .content > div .navbar-expand-xl > .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .navbar-expand-xl > .text-formatted, #custom-search-cepheid .container_wrapper .advanced .navbar-expand-xl > .container-advanced, #wrapper_content_selector .navbar-expand-xl > #edit-output,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container, #page-wrapper #main-wrapper .navbar-expand-xl > #content, #block-views-block-homepage-document-list-block-1 .content > div .navbar-expand-xl > .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .navbar-expand-xl > .text-formatted, #custom-search-cepheid .container_wrapper .advanced .navbar-expand-xl > .container-advanced, #wrapper_content_selector .navbar-expand-xl > #edit-output,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1399.98px) {
  .navbar-expand-xxl > .container, #page-wrapper #main-wrapper .navbar-expand-xxl > #content, #block-views-block-homepage-document-list-block-1 .content > div .navbar-expand-xxl > .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .navbar-expand-xxl > .text-formatted, #custom-search-cepheid .container_wrapper .advanced .navbar-expand-xxl > .container-advanced, #wrapper_content_selector .navbar-expand-xxl > #edit-output,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container, #page-wrapper #main-wrapper .navbar-expand-xxl > #content, #block-views-block-homepage-document-list-block-1 .content > div .navbar-expand-xxl > .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .navbar-expand-xxl > .text-formatted, #custom-search-cepheid .container_wrapper .advanced .navbar-expand-xxl > .container-advanced, #wrapper_content_selector .navbar-expand-xxl > #edit-output,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container, #page-wrapper #main-wrapper .navbar-expand > #content, #block-views-block-homepage-document-list-block-1 .content > div .navbar-expand > .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .navbar-expand > .text-formatted, #custom-search-cepheid .container_wrapper .advanced .navbar-expand > .container-advanced, #wrapper_content_selector .navbar-expand > #edit-output,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container, #page-wrapper #main-wrapper .navbar-expand > #content, #block-views-block-homepage-document-list-block-1 .content > div .navbar-expand > .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .navbar-expand > .text-formatted, #custom-search-cepheid .container_wrapper .advanced .navbar-expand > .container-advanced, #wrapper_content_selector .navbar-expand > #edit-output,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #ffffff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #ffffff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #ffffff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #ffffff; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.25rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007AC3 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #005a90 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #77B800 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #568500 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007AC3 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #77B800 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, #header #modalMenu #navigation .region-menu-top, #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .nav-item a, #header #header-top .menu-toggle {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column, #header #modalMenu #navigation .region-menu-top {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto,
#header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .nav-item a:before,
#header #header-top .baseline,
#header #header-top .menu-toggle .brand,
.media-left .text,
.media-right .text {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto,
#header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .nav-item a:before,
#header #header-top .baseline,
#header #header-top .menu-toggle .brand,
.media-left .text,
.media-right .text {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #007AC3 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #004a77 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #77B800 !important; }

a.text-success:hover, a.text-success:focus {
  color: #466c00 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* TYPOGRAPHY */
/*@include font-face('HelveticaNeueThin', '../assets/fonts/HelveticaNeue-Thin');
@include font-face('HelveticaNeueLight', '../assets/fonts/HelveticaNeue-Light');
@include font-face('HelveticaNeueRegular', '../assets/fonts/HelveticaNeue');
@include font-face('HelveticaNeueMedium', '../assets/fonts/HelveticaNeue-Medium');
@include font-face('HelveticaNeueBold', '../assets/fonts/HelveticaNeue-Bold');
@include font-face('HelveticaNeueHeavy', '../assets/fonts/HelveticaNeue-Heavy');*/
/*$hT: 'HelveticaNeueThin',Arial,Verdana,sans-serif;
$hL: 'HelveticaNeueLight',Arial,Verdana,sans-serif;
$hR: 'HelveticaNeueRegular',Arial,Verdana,sans-serif;
$hM: 'HelveticaNeueMedium',Arial,Verdana,sans-serif;
$hB: 'HelveticaNeueHeavy',Arial,Verdana,sans-serif;
$hSB: 'HelveticaNeueBold',Arial,Verdana,sans-serif;*/
/*@include font-face('openSansR', '../assets/fonts/OpenSans-Regular');
@include font-face('openSansB', '../assets/fonts/OpenSans-Bold');
@include font-face('openSansSB', '../assets/fonts/OpenSans-SemiBold');
@include font-face('openSansI', '../assets/fonts/OpenSans-Italic');*/
/*$openR: 'openSansR',Arial,Verdana,sans-serif; 
$openB: 'openSansB',Arial,Verdana,sans-serif;
$openSB: 'openSansSB',Arial,Verdana,sans-serif;
$openI: 'openSansI',Arial,Verdana,sans-serif;*/
@font-face {
  font-family: "CepheidSansBoldItalic";
  font-display: swap;
  src: url("../assets/fonts/CepheidSans/CepheidSans-Bold-Italic.eot?") format("eot"), url("../assets/fonts/CepheidSans/CepheidSans-Bold-Italic.woff") format("woff"), url("../assets/fonts/CepheidSans/CepheidSans-Bold-Italic.ttf") format("truetype"), url("../assets/fonts/CepheidSans/CepheidSans-Bold-Italic.svg#CepheidSansBoldItalic") format("svg"); }

@font-face {
  font-family: "CepheidSansBold";
  font-display: swap;
  src: url("../assets/fonts/CepheidSans/CepheidSans-Bold.eot?") format("eot"), url("../assets/fonts/CepheidSans/CepheidSans-Bold.woff") format("woff"), url("../assets/fonts/CepheidSans/CepheidSans-Bold.ttf") format("truetype"), url("../assets/fonts/CepheidSans/CepheidSans-Bold.svg#CepheidSansBold") format("svg"); }

@font-face {
  font-family: "CepheidSansItalic";
  font-display: swap;
  src: url("../assets/fonts/CepheidSans/CepheidSans-Italic.eot?") format("eot"), url("../assets/fonts/CepheidSans/CepheidSans-Italic.woff") format("woff"), url("../assets/fonts/CepheidSans/CepheidSans-Italic.ttf") format("truetype"), url("../assets/fonts/CepheidSans/CepheidSans-Italic.svg#CepheidSansItalic") format("svg"); }

@font-face {
  font-family: "CepheidSansLight-Italic";
  font-display: swap;
  src: url("../assets/fonts/CepheidSans/CepheidSans-Light-Italic.eot?") format("eot"), url("../assets/fonts/CepheidSans/CepheidSans-Light-Italic.woff") format("woff"), url("../assets/fonts/CepheidSans/CepheidSans-Light-Italic.ttf") format("truetype"), url("../assets/fonts/CepheidSans/CepheidSans-Light-Italic.svg#CepheidSansLight-Italic") format("svg"); }

@font-face {
  font-family: "CepheidSansLight";
  font-display: swap;
  src: url("../assets/fonts/CepheidSans/CepheidSans-Light.eot?") format("eot"), url("../assets/fonts/CepheidSans/CepheidSans-Light.woff") format("woff"), url("../assets/fonts/CepheidSans/CepheidSans-Light.ttf") format("truetype"), url("../assets/fonts/CepheidSans/CepheidSans-Light.svg#CepheidSansLight") format("svg"); }

@font-face {
  font-family: "CepheidSansRegular";
  font-display: swap;
  src: url("../assets/fonts/CepheidSans/CepheidSans-Regular.eot?") format("eot"), url("../assets/fonts/CepheidSans/CepheidSans-Regular.woff") format("woff"), url("../assets/fonts/CepheidSans/CepheidSans-Regular.ttf") format("truetype"), url("../assets/fonts/CepheidSans/CepheidSans-Regular.svg#CepheidSansRegular") format("svg"); }

@font-face {
  font-family: "CepheidSansSemibold-Italic";
  font-display: swap;
  src: url("../assets/fonts/CepheidSans/CepheidSans-Semibold-Italic.eot?") format("eot"), url("../assets/fonts/CepheidSans/CepheidSans-Semibold-Italic.woff") format("woff"), url("../assets/fonts/CepheidSans/CepheidSans-Semibold-Italic.ttf") format("truetype"), url("../assets/fonts/CepheidSans/CepheidSans-Semibold-Italic.svg#CepheidSansSemibold-Italic") format("svg"); }

@font-face {
  font-family: "CepheidSansSemibold";
  font-display: swap;
  src: url("../assets/fonts/CepheidSans/CepheidSans-Semibold.eot?") format("eot"), url("../assets/fonts/CepheidSans/CepheidSans-Semibold.woff") format("woff"), url("../assets/fonts/CepheidSans/CepheidSans-Semibold.ttf") format("truetype"), url("../assets/fonts/CepheidSans/CepheidSans-Semibold.svg#CepheidSansSemibold") format("svg"); }

@font-face {
  font-family: 'icon';
  src: url("../assets/fonts/icons/icomoon.eot?ggttxe");
  src: url("../assets/fonts/icons/icomoon.eot?ggttxe#iefix") format("embedded-opentype"), url("../assets/fonts/icons/icomoon.ttf?ggttxe") format("truetype"), url("../assets/fonts/icons/icomoon.woff?ggttxe") format("woff"), url("../assets/fonts/icons/icomoon.svg?ggttxe#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-aide .path1:before {
  content: "\e900";
  color: #fbb800; }

.icon-aide .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: white; }

.icon-back-website:before, #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .back:before {
  content: "\e902";
  color: #fff; }

.icon-blue-download .path1:before {
  content: "\e903";
  color: #007ac2; }

.icon-blue-download .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: white; }

.icon-blue-download .path3:before {
  content: "\e905";
  margin-left: -1em;
  color: white; }

.icon-blue-search:before {
  content: "\e906";
  color: #007ac2; }

.icon-blue-video:before {
  content: "\e907";
  color: #007ac2; }

.icon-burger-close:before {
  content: "\e908";
  color: #fff; }

.icon-burger-open:before {
  content: "\e909";
  color: #fff; }

.icon-calendar:before, #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_dates .dates fieldset:before {
  content: "\e90a";
  color: #4d4d4f; }

.icon-cheked:before {
  content: "\e90b";
  color: #007ac2; }

.icon-close-modale:before, .modal-open .modal:not(#modalMenu) .close:before, .modal-open .modal:not(#modalMenu) .btn-close:before, .messages__wrapper .messages .close:before, .messages__wrapper .messages .btn-close:before {
  content: "\e90c";
  color: #fff; }

.icon-close-popup .path1:before {
  content: "\e90d";
  color: #707070; }

.icon-close-popup .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: #4d4d4f; }

.icon-close-popup .path3:before {
  content: "\e90f";
  margin-left: -1em;
  color: #4d4d4f; }

.icon-close-reset .path1:before {
  content: "\e910";
  color: #d7d8d6; }

.icon-close-reset .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: #222222; }

.icon-close-reset .path3:before {
  content: "\e912";
  margin-left: -1em;
  color: #222222; }

.icon-close-search:before {
  content: "\e913";
  color: #a2a2a2; }

.icon-delete:before {
  content: "\e914";
  color: #007ac2; }

.icon-edit:before {
  content: "\e915";
  color: #007ac3; }

.icon-external-link-square:before {
  content: "\e916";
  color: #4d4d4f; }

.icon-eyes:before {
  content: "\e917";
  color: #4d4d4f; }

.icon-faq:before, #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .faq:before {
  content: "\e918";
  color: #fff; }

.icon-filtre:before {
  content: "\e919";
  color: #484949; }

.icon-fleche-input:before {
  content: "\e91a";
  color: #4d4d4f; }

.icon-fleche-top:before {
  content: "\e91b";
  color: #fdb71a; }

.icon-fleche .path1:before {
  content: "\e91c";
  color: #007ac3;
  opacity: 0.236; }

.icon-fleche .path2:before {
  content: "\e91d";
  margin-left: -0.9111328125em;
  color: #007ac3;
  opacity: 0.236; }

.icon-fleche .path3:before {
  content: "\e91e";
  margin-left: -0.9111328125em;
  color: #007ac3; }

.icon-fleche .path4:before {
  content: "\e91f";
  margin-left: -0.9111328125em;
  color: #007ac3; }

.icon-green-faq:before {
  content: "\e920";
  color: #77b800; }

.icon-green-faq1:before {
  content: "\e921";
  color: #77b800; }

.icon-green-less .path1:before {
  content: "\e922";
  color: #77b800; }

.icon-green-less .path2:before {
  content: "\e923";
  margin-left: -1em;
  color: #77b800;
  opacity: 0.219; }

.icon-green-message:before, body.node--type-page .node__content .field--name-field-form #edit-processed-text-01 h2:before {
  content: "\e924";
  color: #77b800; }

.icon-green-profile:before, body.node--type-page .node__content .field--name-field-form [id^='edit-processed-text'] h2:before {
  content: "\e925";
  color: #77b800; }

.icon-green-subscribe:before, #block-alertinformation .text-formatted p:first-child:before {
  content: "\e926";
  color: #77b800; }

.icon-icon:before {
  content: "\e927";
  color: #77b800; }

.icon-info:before {
  content: "\e928";
  color: #bec0bd; }

.icon-log-out:before, #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .logout:before {
  content: "\e929";
  color: #fff; }

.icon-loupe:before {
  content: "\e92a";
  color: #fff; }

.icon-mail:before, #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .contact:before {
  content: "\e92b";
  color: #fff; }

.icon-manage-search:before, #popup[data-type="update"] .modal-header h5:before, #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .savedsearch:before, #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_submit .form-item-saved-search:before {
  content: "\e92c";
  color: #fff; }

.icon-paper:before, #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .policy:before {
  content: "\e92d";
  color: #fff; }

.icon-parametres:before, #block-alertinformation .text-formatted a:before, #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .myaccount:before {
  content: "\e92e";
  color: #fff; }

.icon-log-in:before, #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .login:before {
  content: "\e92f";
  color: #fff; }

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400; }

@media (max-width: 767.98px) {
  html {
    font-size: 0.9rem; } }

/**
 * Firefox specific rule
 */
@-moz-document url-prefix() {
  body {
    font-weight: lighter !important; } }

.custom_checkbox, input[type=checkbox] {
  opacity: 0; }

.custom_checkbox + label, input[type=checkbox] + label {
  padding-left: 30px !important; }

.node-form .custom_checkbox, .node-form input[type=checkbox] {
  opacity: 1; }

.node-form .custom_checkbox + label, .node-form input[type=checkbox] + label {
  padding-left: 0px !important; }

.custom_checkbox:not(:checked) + label:before, input:not(:checked)[type=checkbox] + label:before,
.custom_checkbox:checked + label:before,
input:checked[type=checkbox] + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.4em;
  height: 1.4em;
  border: 1px solid #aaa;
  background: #FFF;
  border-radius: .2em;
  transition: all .275s; }

/* Aspect de la coche */
.custom_checkbox:not(:checked) + label:after, input:not(:checked)[type=checkbox] + label:after,
.custom_checkbox:checked + label:after,
input:checked[type=checkbox] + label:after {
  content: '✓';
  position: absolute;
  top: .65em;
  left: .23em;
  font-size: 1.1em;
  color: #007AC3;
  line-height: 0;
  transition: all .2s;
  /* Petite transition */ }

/* Aspect non cochée */
.custom_checkbox:not(:checked) + label:after, input:not(:checked)[type=checkbox] + label:after {
  opacity: 0;
  transform: scale(0) rotate(45deg); }

/* Aspect cochée */

.custom_checkbox:checked + label:after,
input:checked[type=checkbox] + label:after {
  opacity: 1;
  transform: scale(1) rotate(0); }

/* Disabled checkbox */
.custom_checkbox:disabled:not(:checked) + label:before, input:disabled:not(:checked)[type=checkbox] + label:before,
.custom_checkbox:disabled:checked + label:before,
input:disabled:checked[type=checkbox] + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #e9e9e9; }

/* Disabled checked */
.custom_checkbox:disabled:checked + label:after, input:disabled:checked[type=checkbox] + label:after {
  color: #777; }

.custom_checkbox:disabled + label, input:disabled[type=checkbox] + label {
  color: #aaa; }

body {
  font-weight: 100;
  font-family: CepheidSansLight, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
  color: #007AC2;
  background-color: #ffffff;
  overflow-x: hidden;
  min-height: 100%; }
  body.menu-open {
    overflow: hidden; }

body * {
  font-weight: 400;
  color: #4D4D4F; }
  body * a {
    color: #007AC3; }

.transition, #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .nav-item a, #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .nav-item a:before {
  transition: all 0.3s ease-out; }

/**
  *   Main content (all pages)
  */
#page-wrapper #main > div {
  display: block; }

#page-wrapper #main-wrapper #content {
  background-color: #ffffff; }

@media (max-width: 991.98px) {
  #page-wrapper #main-wrapper {
    padding-right: 15px;
    padding-left: 15px; } }

.back-to-top {
  position: fixed;
  bottom: 100px;
  right: 20px;
  display: none;
  cursor: pointer; }
  .back-to-top span {
    font-size: 40px; }
  @media (max-width: 575.98px) {
    .back-to-top {
      position: inherit;
      left: 0;
      text-align: center;
      top: -18px; } }

/**
  *   Links, titles, alertes, forms and buttons
  */
h1, h2, h3 {
  font-weight: 100;
  font-family: CepheidSansLight, HelveticaNeue, Heebo, Arial, Verdana, sans-serif !important; }

.btn, #block-alertinformation .text-formatted a {
  background-color: #007AC3;
  color: #ffffff;
  padding: 10px 20px;
  border: 1px solid #007AC3;
  border-radius: 3px;
  min-height: 50px; }
  .btn.white, #block-alertinformation .text-formatted a.white {
    background-color: #ffffff;
    color: #007AC3;
    border-color: #ccc; }
  .btn.blue, #block-alertinformation .text-formatted a.blue {
    background-color: #007AC3;
    color: #ffffff;
    border-color: #007AC3; }
  .btn.active, #block-alertinformation .text-formatted a.active, .btn.green, #block-alertinformation .text-formatted a {
    background-color: #77B800;
    border-color: #77B800;
    color: #ffffff; }

input[type="text"],
input[type="password"],
input[type="email"],
input[type="date"] {
  border: none !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5) !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  font-size: 1rem; }
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  input[type="date"]:focus {
    border-color: #007AC3 !important;
    font-weight: 500;
    font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
    color: #007AC3;
    outline: none;
    box-shadow: none; }

input[type=date]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none; }

input[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
  width: 20px;
  height: 20px; }

textarea {
  border: none !important; }

.select2-selection {
  border: none !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5) !important;
  background-color: transparent !important;
  border-radius: 0 !important; }
  .select2-selection .select2-selection__rendered {
    font-size: 0.875rem;
    font-weight: 500;
    font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif; }
  .select2-selection .select2-selection__clear {
    position: absolute;
    right: 10px;
    top: 4px;
    text-align: center;
    line-height: 17px;
    background: rgba(77, 77, 79, 0.6);
    width: 18px;
    height: 18px;
    color: #ffffff;
    border-radius: 18px;
    margin: 2px 0 0 3px; }

.select2 .select2-selection__choice {
  border-radius: 30px !important;
  background-color: #ffffff !important;
  font-size: 0.875rem;
  border: none !important;
  color: #007AC3;
  font-weight: 500;
  font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif; }
  .select2 .select2-selection__choice .select2-selection__choice__remove {
    text-align: center;
    line-height: 17px;
    background: rgba(77, 77, 79, 0.6);
    width: 18px;
    height: 18px;
    float: right;
    color: #ffffff;
    border-radius: 18px;
    margin: 2px 0 0 3px; }

.selection:focus, .select2-selection:focus {
  outline: none !important; }

input::placeholder, .select2-selection__placeholder {
  color: #222222 !important;
  opacity: 1 !important;
  font-size: 1rem; }

body.node--type-page h1 {
  font-size: 2.1875rem;
  padding: 40px 0 20px 0; }

body.node--type-page .node__content .field--name-body {
  max-width: 980px; }
  body.node--type-page .node__content .field--name-body > h2:first-child {
    font-size: 1.5rem;
    padding: 0 0 20px 0; }

.alert-wrapper {
  position: fixed;
  width: 100%;
  text-align: center;
  top: 40%;
  left: 0;
  z-index: 10; }
  @media (max-width: 575.98px) {
    .alert-wrapper {
      width: 75%;
      right: 0;
      margin: 0 auto; } }
  .alert-wrapper .alert {
    background-color: #FDB71A;
    color: #ffffff;
    padding: 30px;
    border-radius: 5px;
    position: relative;
    font-size: 1rem;
    font-weight: 500;
    font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif; }
    @media (max-width: 575.98px) {
      .alert-wrapper .alert {
        padding: 20px; } }
    .alert-wrapper .alert button.close, .alert-wrapper .alert button.btn-close {
      position: absolute;
      top: 0;
      right: 0; }
      .alert-wrapper .alert button.close:before, .alert-wrapper .alert button.btn-close:before {
        color: #ffffff !important; }

.tooltip.show {
  opacity: 1; }

.tooltip .arrow:before {
  border-top-color: #ffffff; }

.tooltip .tooltip-inner {
  background-color: #ffffff;
  color: #222222; }

/**
  *   Webforms (can be linked to any pages)
  */
body.node--type-page .node__content .field--name-field-form {
  background-color: rgba(245, 246, 245, 0.2);
  max-width: 980px;
  margin: 40px 0;
  padding: 40px 70px; }
  @media (max-width: 767.98px) {
    body.node--type-page .node__content .field--name-field-form {
      padding: 40px 50px; } }
  body.node--type-page .node__content .field--name-field-form form#webform-submission-contact-node-8-add-form {
    display: flex;
    flex-direction: column; }
    body.node--type-page .node__content .field--name-field-form form#webform-submission-contact-node-8-add-form #edit-flexbox-02 {
      padding-right: 0;
      width: 50%; }
      @media (max-width: 767.98px) {
        body.node--type-page .node__content .field--name-field-form form#webform-submission-contact-node-8-add-form #edit-flexbox-02 {
          width: 100%;
          padding-right: 15px; } }
    body.node--type-page .node__content .field--name-field-form form#webform-submission-contact-node-8-add-form #edit-actions {
      order: 8; }
    body.node--type-page .node__content .field--name-field-form form#webform-submission-contact-node-8-add-form .captcha {
      order: 7;
      margin: 0 auto 20px auto; }
      @media screen and (max-width: 430px) {
        body.node--type-page .node__content .field--name-field-form form#webform-submission-contact-node-8-add-form .captcha .g-recaptcha iframe {
          margin-left: -30px; } }
  body.node--type-page .node__content .field--name-field-form form#webform-submission-create-an-account-node-13-add-form {
    display: flex;
    flex-direction: column; }
    body.node--type-page .node__content .field--name-field-form form#webform-submission-create-an-account-node-13-add-form #edit-actions {
      order: 8; }
    body.node--type-page .node__content .field--name-field-form form#webform-submission-create-an-account-node-13-add-form .captcha {
      order: 7;
      margin: 0 auto 20px auto; }
      @media screen and (max-width: 430px) {
        body.node--type-page .node__content .field--name-field-form form#webform-submission-create-an-account-node-13-add-form .captcha .g-recaptcha iframe {
          margin-left: -30px; } }
  body.node--type-page .node__content .field--name-field-form [id^='edit-processed-text'] h2 {
    padding-left: 25px;
    margin-bottom: 15px; }
    body.node--type-page .node__content .field--name-field-form [id^='edit-processed-text'] h2:before {
      font-family: 'icon';
      font-size: 2.6875rem;
      position: absolute;
      left: -25px;
      top: -12px; }
  body.node--type-page .node__content .field--name-field-form #edit-processed-text-01 h2 {
    margin-bottom: 15px !important; }
  @media (max-width: 767.98px) {
    body.node--type-page .node__content .field--name-field-form #edit-flexbox, body.node--type-page .node__content .field--name-field-form #edit-flexbox-01, body.node--type-page .node__content .field--name-field-form #edit-flexbox-02, body.node--type-page .node__content .field--name-field-form #edit-flexbox-03 {
      padding-right: 0 !important;
      padding-left: 0 !important; } }
  @media (max-width: 767.98px) {
    body.node--type-page .node__content .field--name-field-form #edit-flexbox fieldset, body.node--type-page .node__content .field--name-field-form #edit-flexbox-01 fieldset, body.node--type-page .node__content .field--name-field-form #edit-flexbox-02 fieldset, body.node--type-page .node__content .field--name-field-form #edit-flexbox-03 fieldset {
      padding-right: 0 !important;
      padding-left: 0 !important; } }
  body.node--type-page .node__content .field--name-field-form #edit-flexbox-03 .fieldset-wrapper fieldset:first-child {
    width: 50%; }
    @media (max-width: 767.98px) {
      body.node--type-page .node__content .field--name-field-form #edit-flexbox-03 .fieldset-wrapper fieldset:first-child {
        width: 100%;
        padding-right: 15px; } }
  body.node--type-page .node__content .field--name-field-form .form-group {
    margin-bottom: 22px;
    position: relative; }
  body.node--type-page .node__content .field--name-field-form .field-suffix {
    position: absolute;
    top: 13px;
    right: 30px; }
    body.node--type-page .node__content .field--name-field-form .field-suffix .webform-element-help {
      border: none;
      background: transparent; }
      body.node--type-page .node__content .field--name-field-form .field-suffix .webform-element-help span {
        display: none; }
      body.node--type-page .node__content .field--name-field-form .field-suffix .webform-element-help:before {
        content: "";
        background: url(../images/help.png) no-repeat;
        position: absolute;
        left: -4px;
        top: 1px;
        width: 20px;
        height: 20px; }
  body.node--type-page .node__content .field--name-field-form div.js-form-type-checkbox {
    margin: 50px 30px;
    padding: 20px 40px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 400;
    font-family: CepheidSansRegular, HelveticaNeue, Heebo, Arial, Verdana, sans-serif; }
    @media (max-width: 767.98px) {
      body.node--type-page .node__content .field--name-field-form div.js-form-type-checkbox {
        margin: 50px 0px;
        padding: 20px 30px; } }
    @media (max-width: 575.98px) {
      body.node--type-page .node__content .field--name-field-form div.js-form-type-checkbox {
        padding: 20px 0px; } }
  body.node--type-page .node__content .field--name-field-form input.btn {
    width: auto;
    margin: 0 auto;
    padding: 10px 40px; }

/**
  *   Update popup modal
  */
#popup[data-type="update"] .modal-header h5 {
  position: relative; }
  #popup[data-type="update"] .modal-header h5:before {
    position: absolute;
    font-family: 'icon' !important;
    color: #77B800;
    font-size: 25px;
    right: 0;
    left: 0;
    bottom: 30px; }

#popup[data-type="update"] .modal-body {
  border-bottom: 1px solid #BEC0BD;
  margin-bottom: 20px; }

/**
  *   Delete popup modal
  */
/**
  *   Common popup modal
  */
.modal-open .modal:not(#modalMenu) {
  text-align: center; }
  .modal-open .modal:not(#modalMenu) .modal-dialog {
    max-width: 890px; }
    @media (max-width: 575.98px) {
      .modal-open .modal:not(#modalMenu) .modal-dialog {
        margin-top: 60px; } }
  .modal-open .modal:not(#modalMenu) .modal-title {
    width: 100%;
    font-weight: 500;
    font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
    font-size: 1.25rem;
    color: #000000;
    font-weight: 700;
    font-family: CepheidSansBold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif; }
  .modal-open .modal:not(#modalMenu) .modal-header {
    border: none; }
    .modal-open .modal:not(#modalMenu) .modal-header h5 {
      font-weight: 500;
      font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
      margin-top: 15px; }
  .modal-open .modal:not(#modalMenu) .modal-body {
    padding: 0 0 20px 0; }
    @media (max-width: 575.98px) {
      .modal-open .modal:not(#modalMenu) .modal-body {
        padding: 0; } }
    .modal-open .modal:not(#modalMenu) .modal-body p span {
      font-weight: 500;
      font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif; }
  .modal-open .modal:not(#modalMenu) .close, .modal-open .modal:not(#modalMenu) .btn-close {
    border: none;
    background: transparent;
    position: absolute;
    right: 30px;
    top: 20px; }
    .modal-open .modal:not(#modalMenu) .close span, .modal-open .modal:not(#modalMenu) .btn-close span {
      display: none; }
    .modal-open .modal:not(#modalMenu) .close:before, .modal-open .modal:not(#modalMenu) .btn-close:before {
      font-family: 'icon';
      font-size: 1.375rem;
      color: #4D4D4F; }
  .modal-open .modal:not(#modalMenu) .modal-content {
    padding: 50px 40px; }
    @media (max-width: 575.98px) {
      .modal-open .modal:not(#modalMenu) .modal-content {
        padding: 20px 40px; } }
  .modal-open .modal:not(#modalMenu) .modal-footer {
    justify-content: center;
    border-top: none;
    font-weight: 400;
    font-family: CepheidSansRegular, HelveticaNeue, Heebo, Arial, Verdana, sans-serif; }
    .modal-open .modal:not(#modalMenu) .modal-footer .btn-secondary {
      background: #ffffff;
      border-color: rgba(77, 77, 79, 0.6);
      color: #707070; }
  .modal-open .modal:not(#modalMenu) form {
    background: rgba(236, 237, 236, 0.25);
    margin-top: 20px;
    padding: 50px 20%; }
    .modal-open .modal:not(#modalMenu) form input[name="search_name"] {
      width: 100%; }
  .modal-open .modal:not(#modalMenu) .apercu_save {
    padding: 0 50px;
    margin-top: 40px;
    text-align: left;
    justify-content: space-between; }
    @media (max-width: 767.98px) {
      .modal-open .modal:not(#modalMenu) .apercu_save {
        flex-direction: column; }
        .modal-open .modal:not(#modalMenu) .apercu_save > div {
          margin: 5px 15px; } }
    .modal-open .modal:not(#modalMenu) .apercu_save > div {
      margin: 0 5px;
      font-weight: 500;
      font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
      max-width: 330px; }
      .modal-open .modal:not(#modalMenu) .apercu_save > div label {
        font-weight: 400;
        font-family: CepheidSansRegular, HelveticaNeue, Heebo, Arial, Verdana, sans-serif; }
  .modal-open .modal:not(#modalMenu).video-modal {
    z-index: 2060; }
    .modal-open .modal:not(#modalMenu).video-modal .modal-content {
      background-color: transparent;
      border: none; }
    .modal-open .modal:not(#modalMenu).video-modal .modal-body {
      border-radius: 5px;
      background-color: #222222; }
      .modal-open .modal:not(#modalMenu).video-modal .modal-body video {
        width: 100%; }
    .modal-open .modal:not(#modalMenu).video-modal .modal-footer {
      text-align: left;
      padding: 0; }
      .modal-open .modal:not(#modalMenu).video-modal .modal-footer .modal-title {
        color: #ffffff;
        font-size: 1rem;
        font-weight: 500;
        font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif; }
    .modal-open .modal:not(#modalMenu).video-modal button.close, .modal-open .modal:not(#modalMenu).video-modal button.btn-close {
      padding: 0;
      position: relative;
      left: 40px;
      font-size: 2.5rem;
      color: #ffffff; }
      .modal-open .modal:not(#modalMenu).video-modal button.close:before, .modal-open .modal:not(#modalMenu).video-modal button.btn-close:before {
        color: #ffffff;
        font-size: 1.875rem; }

.modal-backdrop {
  z-index: 2; }

/**
  *   Homepage
  */
.path-frontpage #header #header-top .cepheid-logo {
  position: relative !important; }

.path-frontpage #header .baseline {
  display: inline-block !important; }

.path-frontpage #block-cepheid-content {
  display: none; }

#block-alertinformation {
  background: #ECEDEC;
  padding: 20px;
  margin-top: 30px; }
  @media (max-width: 991.98px) {
    #block-alertinformation {
      margin-top: 30px;
      margin-bottom: 50px; } }
  @media (max-width: 575.98px) {
    #block-alertinformation {
      order: 2;
      margin-top: 50px;
      margin-bottom: 0; } }
  #block-alertinformation .text-formatted {
    color: #4D4D4F;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around; }
    @media (max-width: 575.98px) {
      #block-alertinformation .text-formatted {
        flex-direction: column; } }
    #block-alertinformation .text-formatted p {
      margin-bottom: 0; }
    #block-alertinformation .text-formatted p:first-child {
      padding-left: 50px;
      position: relative;
      flex: 0 0 49%; }
      @media (max-width: 575.98px) {
        #block-alertinformation .text-formatted p:first-child {
          margin-bottom: 25px;
          padding-top: 12px; } }
      #block-alertinformation .text-formatted p:first-child:before {
        position: absolute;
        left: -10px;
        font-family: 'icon';
        width: 34px;
        height: 45px;
        top: -9px;
        font-size: 2.8125rem; }
    #block-alertinformation .text-formatted a:before {
      font-family: 'icon';
      font-size: 1rem;
      color: #ffffff;
      margin-right: 10px; }
    #block-alertinformation .text-formatted a:hover {
      text-decoration: none; }
    @media all and (-ms-high-contrast: none) {
      #block-alertinformation .text-formatted a {
        display: block; } }

#block-views-block-homepage-document-list-block-1 {
  position: relative;
  top: -30px; }
  @media (max-width: 575.98px) {
    #block-views-block-homepage-document-list-block-1 {
      top: 20px; } }
  #block-views-block-homepage-document-list-block-1 h2 {
    font-family: CepheidSansRegular, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
    font-weight: 100;
    font-size: 2.1875rem;
    position: relative;
    padding: 0 0 5px 0;
    color: #222222;
    margin-bottom: 40px; }
    #block-views-block-homepage-document-list-block-1 h2:after {
      left: 0;
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 45px;
      content: "";
      background: #4D4D4F; }
  #block-views-block-homepage-document-list-block-1 .content > div {
    margin: 0;
    padding: 0; }
    #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list {
      margin: 0;
      padding: 0;
      width: 100%; }
      #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list .view-content {
        padding: 0;
        align-items: flex-start;
        margin: 0;
        flex-direction: column;
        width: 100%; }
        #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list .view-content .views-row {
          display: flex;
          width: 100%; }
          #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list .view-content .views-row > article {
            width: 100%;
            height: 100%; }

/**
  *   Alertes
  */
.messages__wrapper .messages .close, .messages__wrapper .messages .btn-close {
  border: none;
  background: transparent; }
  .messages__wrapper .messages .close span, .messages__wrapper .messages .btn-close span {
    display: none; }
  .messages__wrapper .messages .close:before, .messages__wrapper .messages .btn-close:before {
    font-family: 'icon';
    font-size: 1.375rem;
    color: #4D4D4F; }

/**
  *   Page Not found
  */
body.title-page-not-found #block-cepheid-content > div.content {
  padding: 60px 10px;
  text-align: center; }

/* HEADER DISPLAY */
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.9; }
  .hamburger.is-active:hover {
    opacity: 0.9; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #FFF; }

.hamburger-box {
  width: 37px;
  height: 25px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 37px;
    height: 3px;
    background-color: #FFF;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -11px; }
  .hamburger-inner::after {
    bottom: -11px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 74px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    transform: translate3d(0, 11px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    transform: translate3d(0, -11px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 74px; }

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 11px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -11px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 74px; }

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    transform: translate3d(0, 11px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    transform: translate3d(0, -11px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 74px; }

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 11px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -11px, 0) rotate(-45deg); }

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 74px; }

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg); }
  .hamburger--3dxy.is-active .hamburger-inner::before {
    transform: translate3d(0, 11px, 0) rotate(45deg); }
  .hamburger--3dxy.is-active .hamburger-inner::after {
    transform: translate3d(0, -11px, 0) rotate(-45deg); }

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 74px; }

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 11px, 0) rotate(45deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -11px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-7.4px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-7.4px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(7.4px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(7.4px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-7.4px, -9.25px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-7.4px, 9.25px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(7.4px, -9.25px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(7.4px, 9.25px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg); }
  .hamburger--arrowturn.is-active .hamburger-inner::before {
    transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }
  .hamburger--arrowturn.is-active .hamburger-inner::after {
    transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::before {
    transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::after {
    transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -22px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -11px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -22px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -11px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 11px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 22px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 11px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -22px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 11px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 22px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 11px, 0) rotate(-135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -22px, 0) rotate(270deg);
    transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::after {
      top: 11px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -74px;
      top: -74px;
      transform: translate3d(74px, 74px, 0) rotate(45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -74px;
      top: -74px;
      transform: translate3d(-74px, 74px, 0) rotate(-45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 11px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -74px;
      top: 74px;
      transform: translate3d(74px, -74px, 0) rotate(-45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -74px;
      top: 74px;
      transform: translate3d(-74px, -74px, 0) rotate(45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 1.5px; }
  .hamburger--slider .hamburger-inner::before {
    top: 11px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 22px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 11px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-5.28571px, -8px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -22px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 1.5px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 11px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 22px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 11px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    transform: rotate(45deg) translate3d(5.28571px, -8px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -22px, 0) rotate(90deg); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 1.5px;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 11px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 22px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 11px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 11px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -22px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -11px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand-r .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

/**
*   Body not fixed
*/
body:not(.fixed) #header-top .cepheid-logo {
  margin: 40px 0 20px 0; }
  body:not(.fixed) #header-top .cepheid-logo img {
    /*@include media-breakpoint-down(lg) {
					position: absolute;
					left: 0;
					top: 60px;
					width: 180px;
				}*/ }

/**
*
* Main header
*
*/
#toolbar-administration {
  z-index: 1500; }

#header {
  background: #007AC3;
  background: radial-gradient(circle, #14bef0 0%, #007ac3 72%, #007ac3 97%);
  transition: .6s; }
  #header #modalMenu {
    padding-right: 0;
    transition: opacity ease .5s;
    opacity: 0; }
    #header #modalMenu.show {
      opacity: 1; }
    #header #modalMenu .modal-dialog {
      height: 100%;
      margin: 0 0 0 auto;
      max-width: 405px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1050; }
    #header #modalMenu .modal-content {
      background-color: #007AC2;
      height: 100%;
      border-radius: 0;
      border: none; }
    #header #modalMenu #navigation {
      position: relative; }
      #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul {
        padding: 120px 45px 30px;
        list-style-type: none !important; }
        @media all and (-ms-high-contrast: none) {
          #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul li {
            height: 60px;
            color: #007AC3; } }
        #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .nav-item a {
          border-top: 1px solid rgba(255, 255, 255, 0.2);
          padding: 0.75rem 10px;
          font-size: 1.125rem;
          color: white;
          line-height: 31px; }
          #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .nav-item a:before {
            color: white;
            margin-right: 22px;
            font-size: 1.75rem;
            opacity: .6; }
          #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .nav-item a:hover {
            color: rgba(255, 255, 255, 0.6); }
            #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .nav-item a:hover:before {
              color: rgba(255, 255, 255, 0.6); }
        #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .logout {
          position: absolute;
          top: 0;
          border-top: none !important;
          font-size: 0.8125rem; }
          #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .logout:before {
            font-family: 'icon'; }
        #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .login {
          position: absolute;
          top: 0;
          border-top: none !important;
          font-size: 0.8125rem; }
          #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .login:before {
            font-family: 'icon'; }
        #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .homepage:before {
          content: "";
          background: url(../images/homepage.png) no-repeat;
          width: 29px;
          height: 30px;
          padding: 0;
          background-size: 34px;
          background-position: -3px -3px; }
        #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .homepage:hover:before {
          opacity: .4 !important; }
        #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .myaccount:before {
          font-family: 'icon'; }
        #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .savedsearch:before {
          font-family: 'icon'; }
        #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .faq:before {
          font-family: 'icon'; }
        #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .contact:before {
          font-family: 'icon';
          font-size: 22px !important; }
        #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .policy:before {
          font-family: 'icon'; }
        #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .back:before {
          font-family: 'icon'; }
      #header #modalMenu #navigation .navbar-brand {
        position: absolute; }
  #header #header-top {
    min-height: 100px; }
    @media (max-width: 767.98px) {
      #header #header-top {
        min-height: 80px; } }
    #header #header-top > .container, #header #page-wrapper #main-wrapper #header-top > #content, #page-wrapper #main-wrapper #header #header-top > #content, #header #block-views-block-homepage-document-list-block-1 .content > div #header-top > .view-homepage-document-list, #block-views-block-homepage-document-list-block-1 .content > div #header #header-top > .view-homepage-document-list, #header #page-wrapper #page footer.site-footer .region-footer #block-footer #header-top > .text-formatted, #page-wrapper #page footer.site-footer .region-footer #block-footer #header #header-top > .text-formatted, #header #custom-search-cepheid .container_wrapper .advanced #header-top > .container-advanced, #custom-search-cepheid .container_wrapper .advanced #header #header-top > .container-advanced, #header #wrapper_content_selector #header-top > #edit-output, #wrapper_content_selector #header #header-top > #edit-output {
      position: relative; }
    #header #header-top .cepheid-logo {
      margin: 40px 0 20px 0;
      position: absolute; }
      @media (max-width: 1199.98px) {
        #header #header-top .cepheid-logo img {
          max-width: 180px; } }
    #header #header-top .baseline {
      display: none;
      text-align: center;
      font-size: 1.875rem;
      font-weight: 100;
      font-family: CepheidSansLight, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
      color: #ffffff;
      flex: 0 0 75%;
      margin-bottom: 20px !important;
      padding-right: 100px; }
      @media screen and (max-width: 1400px) {
        #header #header-top .baseline {
          padding-right: 160px; } }
      @media (max-width: 991.98px) {
        #header #header-top .baseline {
          display: none; } }
      #header #header-top .baseline span {
        color: #ffffff;
        display: block;
        font-size: 1.5625rem; }
      @media all and (-ms-high-contrast: none) {
        #header #header-top .baseline {
          margin-top: 50px !important; } }
    #header #header-top .menu-toggle {
      position: absolute;
      right: 0;
      top: 20px;
      color: #ffffff; }
      #header #header-top .menu-toggle .hamburger {
        z-index: 1100; }
      #header #header-top .menu-toggle .brand {
        color: #ffffff;
        font-size: 1.5625rem;
        font-weight: 100;
        font-family: CepheidSansLight, HelveticaNeue, Heebo, Arial, Verdana, sans-serif; }
        @media (max-width: 1399.98px) {
          #header #header-top .menu-toggle .brand {
            font-size: 1.25rem; } }
        @media (max-width: 991.98px) {
          #header #header-top .menu-toggle .brand {
            display: none; } }
        @media all and (-ms-high-contrast: none) {
          #header #header-top .menu-toggle .brand {
            padding-top: 10px; } }
  #header #header-bottom .region-search {
    width: 100%; }
    #header #header-bottom .region-search #block-documentsearchblockwsi {
      width: 100%; }

body.modal-open #header #header-top .menu-toggle {
  position: fixed;
  right: 4px;
  top: 13px;
  z-index: 1060; }
  body.modal-open #header #header-top .menu-toggle .brand {
    display: none !important; }

body:not(.fixed) #header {
  /*@include media-breakpoint-down(lg) {
			padding-top: 30px;
		}*/ }
  body:not(.fixed) #header .region-search .wrapper_search {
    box-shadow: 0px 3px 20px #007AC2; }
    @media (max-width: 767.98px) {
      body:not(.fixed) #header .region-search .wrapper_search {
        margin: inherit;
        box-shadow: none !important; } }
    body:not(.fixed) #header .region-search .wrapper_search fieldset {
      max-width: 100%; }
      @media (max-width: 767.98px) {
        body:not(.fixed) #header .region-search .wrapper_search fieldset {
          max-width: 100%; } }
    body:not(.fixed) #header .region-search .wrapper_search a {
      box-shadow: 0px 3px 20px #007AC2; }
      @media (max-width: 767.98px) {
        body:not(.fixed) #header .region-search .wrapper_search a {
          box-shadow: none !important; } }

@media (max-width: 767.98px) {
  body:not(.fixed) #header-top {
    /*width: 80%;*/
    margin: 0 auto; }
    body:not(.fixed) #header-top > .container, body:not(.fixed) #page-wrapper #main-wrapper #header-top > #content, #page-wrapper #main-wrapper body:not(.fixed) #header-top > #content, body:not(.fixed) #block-views-block-homepage-document-list-block-1 .content > div #header-top > .view-homepage-document-list, #block-views-block-homepage-document-list-block-1 .content > div body:not(.fixed) #header-top > .view-homepage-document-list, body:not(.fixed) #page-wrapper #page footer.site-footer .region-footer #block-footer #header-top > .text-formatted, #page-wrapper #page footer.site-footer .region-footer #block-footer body:not(.fixed) #header-top > .text-formatted, body:not(.fixed) #custom-search-cepheid .container_wrapper .advanced #header-top > .container-advanced, #custom-search-cepheid .container_wrapper .advanced body:not(.fixed) #header-top > .container-advanced, body:not(.fixed) #wrapper_content_selector #header-top > #edit-output, #wrapper_content_selector body:not(.fixed) #header-top > #edit-output {
      max-width: none; } }

@media (max-width: 1199.98px) {
  body:not(.fixed) #header-top .baseline {
    width: 100%;
    margin: 0 auto;
    font-size: 25px; }
    body:not(.fixed) #header-top .baseline span {
      font-size: 20px; } }

@media (max-width: 767.98px) {
  body:not(.fixed) #header-top .baseline {
    display: none !important; } }

body:not(.fixed) #header-top .cepheid-logo {
  margin: 40px 0 20px 0; }
  @media (max-width: 767.98px) {
    body:not(.fixed) #header-top .cepheid-logo {
      display: block;
      width: 100%;
      text-align: left;
      margin: 15px 0 10px 0 !important; } }
  @media (max-width: 767.98px) {
    body:not(.fixed) #header-top .cepheid-logo img {
      margin: 0 auto;
      max-width: 110px !important; } }

@media (min-width: 576px) {
  body.page-user-reset #header-top,
  body.page-user-password #header-top,
  body.page-user-register #header-top,
  body.page-node-13:not(.fixed) #header-top,
  body.page-node-8:not(.fixed) #header-top,
  body.page-node-9:not(.fixed) #header-top {
    min-height: 190px !important; } }

.adminimal-admin-toolbar.toolbar-tray-open.toolbar-horizontal #header #modalMenu .modal-content {
  margin-top: 79px; }

body.fixed #page-wrapper {
  padding-top: 280px; }

body.fixed .menu-toggle {
  top: 10px !important; }

body.fixed #header {
  position: fixed;
  top: 0;
  height: 80px;
  z-index: 9; }
  body.fixed #header .baseline, body.fixed #header .brand {
    display: none !important; }
  body.fixed #header #header-top .cepheid-logo {
    margin: 5px 0; }
    body.fixed #header #header-top .cepheid-logo img {
      height: 70px; }
  body.fixed #header .region-search .advanced.active {
    position: relative;
    top: -106px; }
    @media (max-width: 767.98px) {
      body.fixed #header .region-search .advanced.active {
        top: 0px; } }
  body.fixed #header .region-search .wrapper_search {
    position: relative;
    top: -84px; }
    @media (max-width: 767.98px) {
      body.fixed #header .region-search .wrapper_search {
        position: static;
        margin: inherit;
        width: calc(100% - 120px) !important; } }
    @media (max-width: 991.98px) {
      body.fixed #header .region-search .wrapper_search {
        width: 40%; } }
    body.fixed #header .region-search .wrapper_search .form-item-input-search #edit-input-search {
      font-weight: 500;
      font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
      height: 50px; }
      body.fixed #header .region-search .wrapper_search .form-item-input-search #edit-input-search:focus {
        font-weight: 500;
        font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif; }
    body.fixed #header .region-search .wrapper_search > a {
      width: 60px;
      height: 50px;
      line-height: 50px; }
      body.fixed #header .region-search .wrapper_search > a .icon-filtre {
        font-size: 19px; }
    body.fixed #header .region-search .wrapper_search .submiter {
      width: 60px;
      height: 50px; }
      body.fixed #header .region-search .wrapper_search .submiter .submit {
        top: 13px;
        left: 17px; }
      body.fixed #header .region-search .wrapper_search .submiter #edit-submit-btn {
        width: 60px;
        height: 50px;
        line-height: 50px; }

#page-wrapper {
  min-height: 100vh; }
  #page-wrapper #page {
    padding-bottom: 44px;
    min-height: 100vh;
    position: relative; }
    #page-wrapper #page footer.site-footer {
      position: absolute;
      bottom: 0;
      width: 100vw; }
      #page-wrapper #page footer.site-footer .region-footer {
        margin: 0; }
        #page-wrapper #page footer.site-footer .region-footer #block-footer {
          width: 100%;
          background-color: rgba(77, 77, 79, 0.95);
          color: #ffffff; }
          @media (max-width: 575.98px) {
            #page-wrapper #page footer.site-footer .region-footer #block-footer {
              padding: 0px 30px; } }
          @media (max-width: 575.98px) {
            #page-wrapper #page footer.site-footer .region-footer #block-footer ul {
              display: none !important; } }
          #page-wrapper #page footer.site-footer .region-footer #block-footer a, #page-wrapper #page footer.site-footer .region-footer #block-footer p {
            color: #ffffff;
            font-size: 0.8125rem; }
          #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted {
            justify-content: space-between;
            align-items: center;
            display: flex;
            padding: 10px 0; }
            @media (max-width: 767.98px) {
              #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted {
                padding: 10px 25px; } }
            @media (max-width: 575.98px) {
              #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted {
                padding: 15px 0;
                flex-direction: column; }
                #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted p {
                  text-align: center; }
                  #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted p:nth-child(2) {
                    order: 3; }
                  #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted p:nth-child(3) {
                    order: 2; } }
            #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted > * {
              width: 100%;
              display: block;
              margin: 0; }
            #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted > p {
              text-align: center; }
            #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted > p:last-of-type {
              text-align: right !important;
              text-transform: uppercase; }
              @media (max-width: 575.98px) {
                #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted > p:last-of-type {
                  text-align: center !important;
                  margin-top: 6px; } }
            #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted > ul {
              display: flex;
              padding: 0;
              list-style-type: none; }
              #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted > ul li {
                padding-right: 30px; }
                @media (max-width: 767.98px) {
                  #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted > ul li {
                    padding-right: 10px; } }

/**
*
*   Rangées wysiwyg (conteneurs et éléments)
*
*/
/**
*   Liste des éléments
*/
[class^="conteneur_"] {
  padding-left: 0;
  padding-right: 0; }
  [class^="conteneur_"] p {
    margin-bottom: 10px; }
    [class^="conteneur_"] p strong {
      color: #fd7e14; }
  [class^="conteneur_"] .embedded-entity img {
    width: 100%; }
  [class^="conteneur_"] h2 {
    font-size: 1.75rem;
    font-weight: 400;
    font-family: CepheidSansRegular, HelveticaNeue, Heebo, Arial, Verdana, sans-serif !important; }
  [class^="conteneur_"] h3 {
    font-size: 1.4375rem;
    color: #007AC3;
    font-weight: 400;
    font-family: CepheidSansRegular, HelveticaNeue, Heebo, Arial, Verdana, sans-serif !important; }
    [class^="conteneur_"] h3 b, [class^="conteneur_"] h3 strong {
      font-weight: 500;
      font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
      color: #000000; }
  [class^="conteneur_"] ul {
    list-style: none !important;
    /* Remove default bullets */ }
    [class^="conteneur_"] ul li {
      padding-bottom: 5px; }
      [class^="conteneur_"] ul li:before {
        content: "\2022";
        color: #007AC3;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em; }
      [class^="conteneur_"] ul li .ck-list-bogus-paragraph {
        display: inline !important; }

.element_cta_gris {
  background: rgba(0, 0, 0, 0.07);
  padding: 60px 40px 40px 40px;
  position: relative; }
  .element_cta_gris > br {
    display: none; }
  @media (max-width: 991.98px) {
    .element_cta_gris {
      margin-bottom: 10px; } }
  .element_cta_gris.with_photo {
    padding: 40px 40px 20px 40px;
    position: relative; }
    @media (max-width: 991.98px) {
      .element_cta_gris.with_photo {
        margin-bottom: 10px; } }
  .element_cta_gris img {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 43px;
    height: 43px; }
    @media (max-width: 991.98px) {
      .element_cta_gris img {
        top: 20px;
        right: 20px;
        width: 33px;
        height: 33px; } }
  .element_cta_gris .embedded-entity,
  .element_cta_gris .cke_widget_image {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 43px;
    height: 43px; }
    .element_cta_gris .embedded-entity img,
    .element_cta_gris .cke_widget_image img {
      width: 100%; }
  .element_cta_gris div.photo {
    position: absolute;
    top: -20px;
    right: 30px; }
  .element_cta_gris h2 {
    font-size: 1.5rem; }
  .element_cta_gris > a {
    min-width: 110px; }

.element_citation {
  padding: 30px 20px; }
  .element_citation p:first-child {
    position: relative;
    display: block;
    font-size: 1.5625rem; }
    .element_citation p:first-child:before {
      position: absolute;
      top: 0;
      left: -20px;
      content: "";
      width: 47px;
      height: 42px;
      background: url("/themes/custom/cepheid/images/quote.png") no-repeat;
      background-size: 47px 42px; }
  .element_citation span {
    display: block;
    color: #000000;
    position: relative;
    font-size: 0.875rem;
    padding-top: 20px; }
    .element_citation span:after {
      position: absolute;
      top: 0;
      left: 0;
      border-top: 1px dotted rgba(0, 122, 195, 0.4);
      content: "";
      width: 70px;
      height: 1px; }

/**
*   Liste des conteneurs
*/
div[class^="conteneur_"] {
  margin-top: 20px;
  margin-bottom: 20px; }
  div[class^="conteneur_"]:first-child {
    margin-top: 0; }

.conteneur_full .element_cta_gris {
  padding: 60px 40px 40px 40px;
  text-align: center; }
  .conteneur_full .element_cta_gris > img,
  .conteneur_full .element_cta_gris > .embedded-entity,
  .conteneur_full .element_cta_gris .cke_widget_image {
    left: 50%;
    margin-left: -23px;
    top: 45px; }
  .conteneur_full .element_cta_gris h2 {
    margin-top: 50px; }
  .conteneur_full .element_cta_gris .btn, .conteneur_full .element_cta_gris #block-alertinformation .text-formatted a, #block-alertinformation .text-formatted .conteneur_full .element_cta_gris a {
    margin-top: 20px; }

.conteneur_full .element_cta_bleu {
  padding: 60px 40px 60px 40px;
  text-align: center; }
  .conteneur_full .element_cta_bleu h2 {
    margin-top: 10px; }
  .conteneur_full .element_cta_bleu .btn, .conteneur_full .element_cta_bleu #block-alertinformation .text-formatted a, #block-alertinformation .text-formatted .conteneur_full .element_cta_bleu a {
    margin-top: 20px; }

.conteneur_1_col > div {
  width: 100%; }

.conteneur_1_col,
.conteneur_2_col,
.conteneur_3_col,
.conteneur_6_col {
  margin-left: auto;
  margin-right: auto; }

/**
*   Style apparent dans l'éditeur wysiwyg
*/
.media-left {
  padding-left: 0; }
  @media (max-width: 991.98px) {
    .media-left {
      padding-right: 0; } }
  .media-left .media {
    padding-left: 0; }
    @media (max-width: 991.98px) {
      .media-left .media {
        padding-right: 0; } }
    .media-left .media .embedded-entity, .media-left .media p, .media-left .media img {
      width: 100%; }
  .media-left .text {
    padding-right: 6%;
    padding-left: 5%; }

.media-right {
  padding-right: 0; }
  .media-right .media {
    padding-right: 0; }
    .media-right .media .embedded-entity, .media-right .media p, .media-right .media img {
      width: 100%; }
  .media-right .text {
    padding-left: 6%;
    padding-right: 5%; }

body.cke_editable,
.ck-editor__editable {
  background: #ffffff !important; }
  body.cke_editable *,
  .ck-editor__editable * {
    animation-name: none !important; }
  body.cke_editable [class^="conteneur_"],
  .ck-editor__editable [class^="conteneur_"] {
    width: 98%;
    margin: 10px 10px 20px 10px;
    padding: 5px;
    border: 2px dashed #999;
    min-height: 50px;
    max-width: none !important; }
    body.cke_editable [class^="conteneur_"] > div,
    .ck-editor__editable [class^="conteneur_"] > div {
      border: 2px dashed #ccc;
      min-height: 50px; }
  body.cke_editable [class^="element_"],
  .ck-editor__editable [class^="element_"] {
    min-height: 50px;
    margin: 5px; }

/**
*   Main search form (all pages)
*/
body .select2-container {
  width: auto !important; }
  body .select2-container [type="search"] {
    width: 100% !important; }

body .select2-container--open .selection .select2-selection {
  border-bottom: 2px solid #007AC2 !important; }

body .select2-container--open .select2-search.select2-search--inline {
  min-width: 310px; }

body .select2-container--open .select2-dropdown {
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  min-width: 130px; }
  body .select2-container--open .select2-dropdown .select2-results > ul.select2-results__options {
    padding: 10px;
    max-height: 300px; }
    body .select2-container--open .select2-dropdown .select2-results > ul.select2-results__options li.select2-results__option {
      background-color: #ffffff;
      color: #000000;
      font-weight: 400;
      font-family: CepheidSansRegular, HelveticaNeue, Heebo, Arial, Verdana, sans-serif; }
      body .select2-container--open .select2-dropdown .select2-results > ul.select2-results__options li.select2-results__option:hover {
        background-color: #E5F1F9;
        color: #007AC2; }

body #select2-edit-produit-results .select2-results__option {
  padding: 0px 6px; }
  body #select2-edit-produit-results .select2-results__option > span {
    padding: 6px 0;
    display: inline-block; }
  body #select2-edit-produit-results .select2-results__option .arrow {
    left: -7px;
    position: relative;
    width: 10px;
    display: inline-block;
    height: 10px; }
    body #select2-edit-produit-results .select2-results__option .arrow:before {
      content: "";
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 5px;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }
  body #select2-edit-produit-results .select2-results__option .level1 {
    color: #000000;
    padding-left: 10px; }
  body #select2-edit-produit-results .select2-results__option.open .level1 .arrow:before {
    transform: rotate(180deg); }
  body #select2-edit-produit-results .select2-results__option .level2 {
    display: none;
    padding-left: 20px; }
  body #select2-edit-produit-results .select2-results__option .level3 {
    display: none;
    padding-left: 25px; }
  body #select2-edit-produit-results .select2-results__option .level4 {
    display: none;
    padding-left: 30px; }

#custom-search-cepheid .bootstrap-tagsinput .tag {
  background: #888; }

#custom-search-cepheid .container_wrapper {
  display: flex;
  flex-direction: column;
  background-color: transparent; }
  #custom-search-cepheid .container_wrapper .wrapper_search {
    display: flex;
    width: 80%;
    max-width: 500px;
    margin: 0 auto 20px auto; }
    @media (max-width: 767.98px) {
      #custom-search-cepheid .container_wrapper .wrapper_search {
        width: calc(100% - 140px);
        margin: 0;
        box-shadow: none !important; } }
    #custom-search-cepheid .container_wrapper .wrapper_search.active {
      margin: 0 auto; }
    #custom-search-cepheid .container_wrapper .wrapper_search .form-item-input-search {
      padding: 0;
      margin: 0;
      background: #ffffff; }
      #custom-search-cepheid .container_wrapper .wrapper_search .form-item-input-search #edit-input-search {
        border-radius: 0;
        margin: 0;
        height: 70px;
        padding: 10px 20px;
        border: none;
        border-bottom: none !important;
        background-image: none !important; }
        @media (max-width: 767.98px) {
          #custom-search-cepheid .container_wrapper .wrapper_search .form-item-input-search #edit-input-search {
            border-bottom: 1px solid grey;
            max-width: none; } }
        #custom-search-cepheid .container_wrapper .wrapper_search .form-item-input-search #edit-input-search.ui-autocomplete-loading {
          background-image: url(../images/throbber-grey.png) !important; }
    #custom-search-cepheid .container_wrapper .wrapper_search #edit-submit-btn {
      display: inline-block;
      width: 70px;
      height: 70px;
      flex: 0 0 auto;
      background-color: #007AC3;
      line-height: 70px;
      text-align: center;
      border: none;
      border-radius: 0; }
    #custom-search-cepheid .container_wrapper .wrapper_search .submiter {
      position: relative;
      width: 70px;
      height: 70px; }
      #custom-search-cepheid .container_wrapper .wrapper_search .submiter .submit {
        color: #fff;
        font-size: 27px;
        pointer-events: none;
        position: absolute;
        top: 20px;
        left: 20px; }
      #custom-search-cepheid .container_wrapper .wrapper_search .submiter .ajax-progress {
        position: absolute;
        top: 30px;
        right: -30px;
        width: 30px;
        height: 30px; }
        @media (max-width: 767.98px) {
          #custom-search-cepheid .container_wrapper .wrapper_search .submiter .ajax-progress {
            position: absolute;
            top: 23px;
            right: 17px; } }
        #custom-search-cepheid .container_wrapper .wrapper_search .submiter .ajax-progress .throbber {
          width: 15px;
          height: 15px;
          display: block;
          background: transparent url(../images/throbber.png) no-repeat 0 center; }
    #custom-search-cepheid .container_wrapper .wrapper_search > a {
      display: inline-block;
      width: 70px;
      height: 70px;
      flex: 0 0 auto;
      background-color: #D7D8D6;
      line-height: 70px;
      text-align: center;
      font-size: 27px;
      border: none; }
      #custom-search-cepheid .container_wrapper .wrapper_search > a:hover {
        text-decoration: none; }
      #custom-search-cepheid .container_wrapper .wrapper_search > a .icon-filtre {
        font-size: 22px; }
      #custom-search-cepheid .container_wrapper .wrapper_search > a .icon-fleche {
        display: none !important; }
      #custom-search-cepheid .container_wrapper .wrapper_search > a.active {
        background-color: #E5F1F9; }
        #custom-search-cepheid .container_wrapper .wrapper_search > a.active .icon-filtre {
          display: none; }
        #custom-search-cepheid .container_wrapper .wrapper_search > a.active .icon-fleche {
          display: inline-block !important;
          font-size: 19px; }
  #custom-search-cepheid .container_wrapper .advanced {
    padding: 20px 0;
    display: flex;
    background: #E5F1F9;
    height: auto;
    opacity: 1;
    transition: all ease .4s; }
    #custom-search-cepheid .container_wrapper .advanced:not(.active) {
      height: 0;
      opacity: 0;
      padding: 0;
      overflow: hidden;
      pointer-events: none; }
    #custom-search-cepheid .container_wrapper .advanced label {
      visibility: hidden;
      display: block;
      font-size: 0.8125rem;
      margin: 5px 0 0 5px;
      color: #007AC3; }
      #custom-search-cepheid .container_wrapper .advanced label.selected {
        visibility: visible; }
    #custom-search-cepheid .container_wrapper .advanced .select2 {
      display: block; }
      @media (max-width: 991.98px) {
        #custom-search-cepheid .container_wrapper .advanced .select2 {
          width: 100% !important; } }
      #custom-search-cepheid .container_wrapper .advanced .select2.select2-container--open ~ label {
        visibility: visible; }
      #custom-search-cepheid .container_wrapper .advanced .select2 .select2-selection {
        border: none;
        border-radius: 0;
        border-bottom: 2px solid rgba(77, 77, 79, 0.6); }
      #custom-search-cepheid .container_wrapper .advanced .select2 .select2-selection__choice {
        border-radius: 30px;
        background: #ffffff;
        font-size: 0.875rem;
        border: none;
        color: #007AC3;
        font-weight: 500;
        font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif; }
        #custom-search-cepheid .container_wrapper .advanced .select2 .select2-selection__choice .select2-selection__choice__remove {
          text-align: center;
          line-height: 17px;
          background: rgba(77, 77, 79, 0.6);
          width: 18px;
          height: 18px;
          float: right;
          color: #ffffff;
          border-radius: 18px;
          margin: 2px 0 0 3px; }
    #custom-search-cepheid .container_wrapper .advanced .container-advanced {
      display: flex; }
      @media (max-width: 991.98px) {
        #custom-search-cepheid .container_wrapper .advanced .container-advanced {
          flex-direction: column; } }
      #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_categories, #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_dates {
        padding-top: 4px;
        display: flex;
        flex-direction: column;
        flex: 0 1 43%;
        margin-right: 2%; }
        #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_categories .dropdown-wrapper, #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_dates .dropdown-wrapper {
          position: absolute;
          top: 20px;
          right: 8px;
          pointer-events: none;
          border-color: #888 transparent transparent transparent;
          border-style: solid;
          border-width: 5px 4px 0 4px;
          height: 0;
          margin-left: -4px;
          margin-top: -2px;
          position: absolute;
          width: 0; }
      #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_dates {
        flex: 0 1 27%;
        margin-right: 2%;
        display: flex;
        flex-direction: column; }
        #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_dates .dates {
          display: flex; }
          #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_dates .dates fieldset {
            flex: 0 0 49%;
            position: relative;
            margin: 0 0 16px 0; }
            @media all and (-ms-high-contrast: none) {
              #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_dates .dates fieldset {
                max-width: 49%; } }
            #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_dates .dates fieldset:first-child {
              margin-right: 2%; }
            #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_dates .dates fieldset input {
              font-size: 0.875rem;
              padding: 0;
              height: 26px;
              margin-top: 12px; }
            #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_dates .dates fieldset:before {
              font-family: 'icon';
              position: absolute;
              top: 26px;
              right: 35px;
              font-size: 22px;
              color: rgba(0, 0, 0, 0.6);
              pointer-events: none; }
            #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_dates .dates fieldset.selected:before {
              color: #007AC3; }
      #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_submit {
        flex: 0 1 23%;
        display: flex;
        flex-direction: column; }
        #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_submit .ajax-progress {
          display: none; }
        #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_submit .form-item-saved-search {
          position: relative; }
          #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_submit .form-item-saved-search .select2-container {
            padding-top: 10px; }
          #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_submit .form-item-saved-search:before {
            font-family: 'icon';
            position: absolute;
            top: 29px;
            left: 16px;
            font-size: 19px;
            color: rgba(0, 0, 0, 0.6);
            pointer-events: none; }
          #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_submit .form-item-saved-search .select2-selection__rendered {
            padding-left: 35px;
            font-size: 1rem;
            line-height: 24px;
            font-weight: 400; }
          #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_submit .form-item-saved-search .select2-selection__clear {
            display: none; }
        #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_submit #edit-submit-btn2 {
          max-width: 240px;
          margin-left: 10px;
          margin-top: 22px;
          font-weight: 500;
          font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
          border: none;
          border-radius: 5px;
          text-align: center;
          color: #ffffff;
          font-size: 1rem;
          background-color: #007AC3;
          background: url("../images/search_input.png") #007AC3 no-repeat 28px 22px;
          background-position: 31.5% 8px; }
          @media (max-width: 1399.98px) {
            #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_submit #edit-submit-btn2 {
              background-position: 20% 8px; } }
          @media (max-width: 991.98px) {
            #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_submit #edit-submit-btn2 {
              width: 300px;
              margin: 34px auto 0 auto; } }
          @media (max-width: 575.98px) {
            #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_submit #edit-submit-btn2 {
              width: 250px;
              height: 38px; } }
          #custom-search-cepheid .container_wrapper .advanced .container-advanced .wrapper_submit #edit-submit-btn2[disabled] {
            background-color: #A1A1A2;
            background-image: url(../images/throbber-grey.png) !important; }

/**
*   Main search result document list (all pages)
*/
#wrapper_content_selector {
  margin-bottom: 40px; }
  @media (max-width: 575.98px) {
    #wrapper_content_selector #content_selector {
      display: flex;
      flex-direction: column; } }
  #wrapper_content_selector #edit-output {
    padding: 0; }
    #wrapper_content_selector #edit-output > .wrapper {
      display: flex;
      justify-content: space-between;
      margin: 0 0 20px 0; }
      @media (max-width: 767.98px) {
        #wrapper_content_selector #edit-output > .wrapper {
          flex-direction: column;
          text-align: center; } }
      #wrapper_content_selector #edit-output > .wrapper .title {
        font-size: 2.1875rem;
        margin-top: 50px;
        color: #222222; }
        @media (max-width: 991.98px) {
          #wrapper_content_selector #edit-output > .wrapper .title {
            font-size: 1.875rem;
            margin-top: 30px; } }
      #wrapper_content_selector #edit-output > .wrapper .categories > span:not(.restricted) {
        background-color: rgba(215, 216, 214, 0.2);
        margin-right: 10px;
        font-size: 0.8125rem;
        border-radius: 10px;
        padding: 2px 10px;
        font-weight: 500;
        font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif; }
        @media (max-width: 767.98px) {
          #wrapper_content_selector #edit-output > .wrapper .categories > span:not(.restricted) {
            margin-bottom: 10px;
            display: inline-block; } }
      #wrapper_content_selector #edit-output > .wrapper .categories .reset {
        text-decoration: underline;
        font-size: 0.8125rem;
        color: #4D4D4F; }
      @media (max-width: 767.98px) {
        #wrapper_content_selector #edit-output > .wrapper .categories {
          padding: 0 10px 20px 10px;
          display: inline-block; } }
      #wrapper_content_selector #edit-output > .wrapper .save-wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-end; }
        #wrapper_content_selector #edit-output > .wrapper .save-wrapper .save_this_search {
          line-height: 28px; }
          #wrapper_content_selector #edit-output > .wrapper .save-wrapper .save_this_search:hover {
            text-decoration: none; }
          #wrapper_content_selector #edit-output > .wrapper .save-wrapper .save_this_search span {
            margin-right: 10px; }

@media (min-width: 768px) {
  body.advanced_form_open.fixed #wrapper_content_selector {
    padding-top: 220px; } }

.apercu_save > div label {
  color: #007AC3;
  display: block;
  font-size: 0.75rem; }

#content_selector .pager {
  font-size: 1.125rem;
  text-align: center;
  margin: 40px auto; }
  #content_selector .pager .progress {
    margin: 10px auto 0 auto;
    overflow: hidden;
    border-radius: 8px;
    height: 8px;
    width: 300px;
    background-color: #D7D8D6; }
    #content_selector .pager .progress .percent {
      transition: all ease 2.5s;
      background-color: #007AC3;
      height: 8px; }
  #content_selector .pager button {
    margin-top: 20px; }

body > .ui-menu {
  padding: 10px 0;
  max-width: 500px;
  max-height: 500px;
  overflow-y: auto;
  margin: 0 10px;
  border: none !important;
  border-top: 1px solid #f1f1f1 !important;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1); }
  body > .ui-menu li.ui-menu-item {
    margin: 0 20px;
    padding: 5px 10px;
    max-height: 400px;
    overflow: auto; }
    body > .ui-menu li.ui-menu-item a {
      font-weight: 500;
      font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
      color: #222222;
      border: none !important;
      background: none !important; }
    body > .ui-menu li.ui-menu-item:hover {
      background-color: rgba(0, 122, 195, 0.2); }
      body > .ui-menu li.ui-menu-item:hover a {
        color: #007AC3; }

body.fixed .container_wrapper .advanced {
  background-color: rgba(229, 241, 249, 0.96) !important; }

/**
*   Document teaser view
*/
.node--type-document.node--view-mode-teaser {
  background: rgba(236, 237, 236, 0.25);
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 37px; }
  @media (max-width: 575.98px) {
    .node--type-document.node--view-mode-teaser {
      flex-wrap: wrap;
      position: relative; } }
  .node--type-document.node--view-mode-teaser:after {
    flex: 1; }
  .node--type-document.node--view-mode-teaser .info {
    display: flex;
    flex-direction: column;
    flex: 0 0 60%;
    position: relative; }
    @media (max-width: 575.98px) {
      .node--type-document.node--view-mode-teaser .info {
        flex: 0 0 100%; } }
    .node--type-document.node--view-mode-teaser .info button {
      position: absolute;
      left: -34px;
      border: none;
      top: 3px;
      background: transparent; }
      .node--type-document.node--view-mode-teaser .info button:before {
        font-size: 1.125rem; }
    .node--type-document.node--view-mode-teaser .info .title {
      font-size: 1.125rem;
      color: #000000;
      margin-bottom: 5px;
      font-weight: 500;
      font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif; }
    .node--type-document.node--view-mode-teaser .info .categories {
      margin-bottom: 5px; }
      .node--type-document.node--view-mode-teaser .info .categories .restricted {
        display: inline;
        color: #ffffff;
        background-color: #fd7e14;
        font-size: 0.75rem;
        font-weight: 500;
        font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
        padding: 1px 6px;
        border-radius: 10px; }
      .node--type-document.node--view-mode-teaser .info .categories .field__label {
        display: none; }
      .node--type-document.node--view-mode-teaser .info .categories > div,
      .node--type-document.node--view-mode-teaser .info .categories .field__items {
        display: inline; }
      .node--type-document.node--view-mode-teaser .info .categories > div > .field__item,
      .node--type-document.node--view-mode-teaser .info .categories > .field__item,
      .node--type-document.node--view-mode-teaser .info .categories .field__item {
        display: inline;
        color: #007AC3;
        background-color: rgba(0, 122, 195, 0.1);
        font-size: 0.75rem;
        font-weight: 500;
        font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
        padding: 1px 6px;
        border-radius: 10px;
        white-space: nowrap; }
    .node--type-document.node--view-mode-teaser .info .reference .ref {
      display: none;
      font-weight: 400;
      font-family: CepheidSansRegular, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
      font-size: 0.8125rem;
      color: #000000;
      transition: color ease .3s; }
      .node--type-document.node--view-mode-teaser .info .reference .ref.green, .node--type-document.node--view-mode-teaser .info .reference #block-alertinformation .text-formatted a.ref, #block-alertinformation .text-formatted .node--type-document.node--view-mode-teaser .info .reference a.ref {
        color: #77B800; }
      .node--type-document.node--view-mode-teaser .info .reference .ref.active {
        display: inline-block; }
  .node--type-document.node--view-mode-teaser .languages {
    flex: 0 0 25%;
    align-self: center;
    font-weight: 500;
    font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
    font-size: 0.875rem; }
    .node--type-document.node--view-mode-teaser .languages .version {
      padding: 0 0 3px 0;
      font-weight: 400;
      font-family: CepheidSansRegular, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
      font-size: 1.125rem; }
    .node--type-document.node--view-mode-teaser .languages label {
      display: block;
      font-weight: 400;
      font-family: CepheidSansRegular, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
      font-size: 0.875rem;
      margin: 0; }
    .node--type-document.node--view-mode-teaser .languages .select2-selection {
      border-bottom: 1px solid rgba(0, 0, 0, 0.8) !important; }
      .node--type-document.node--view-mode-teaser .languages .select2-selection .select2-selection__rendered {
        font-weight: 500;
        font-family: CepheidSansSemibold, HelveticaNeue, Heebo, Arial, Verdana, sans-serif;
        font-size: 0.875rem;
        padding-left: 0; }
      .node--type-document.node--view-mode-teaser .languages .select2-selection .select2-selection__arrow b {
        border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent; }
  .node--type-document.node--view-mode-teaser .files {
    flex: 0 0 15%;
    align-self: center;
    text-align: right; }
    @media (max-width: 575.98px) {
      .node--type-document.node--view-mode-teaser .files {
        flex: 0 0 65%;
        position: absolute;
        right: 20px;
        bottom: 10px; } }
    .node--type-document.node--view-mode-teaser .files .icon-blue-video {
      padding-right: 0;
      background: transparent;
      border: none;
      font-size: 2.3125rem; }
      @media (max-width: 575.98px) {
        .node--type-document.node--view-mode-teaser .files .icon-blue-video {
          font-size: 2.0625rem; } }
    .node--type-document.node--view-mode-teaser .files .download {
      width: 36px;
      height: 36px;
      display: none;
      position: relative;
      margin-left: 12px; }
      .node--type-document.node--view-mode-teaser .files .download:hover {
        text-decoration: none;
        opacity: 0.95; }
      .node--type-document.node--view-mode-teaser .files .download > span {
        font-size: 37px; }
        @media (max-width: 575.98px) {
          .node--type-document.node--view-mode-teaser .files .download > span {
            font-size: 2.0625rem; } }
      .node--type-document.node--view-mode-teaser .files .download.active {
        display: inline-block; }
      .node--type-document.node--view-mode-teaser .files .download .icon-back-website, .node--type-document.node--view-mode-teaser .files .download #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .back, #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .node--type-document.node--view-mode-teaser .files .download .back {
        font-size: 10px;
        position: absolute;
        right: -5px;
        display: none; }
        .node--type-document.node--view-mode-teaser .files .download .icon-back-website:before, .node--type-document.node--view-mode-teaser .files .download #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .back:before, #header #modalMenu #navigation .region-menu-top #block-cepheid-main-menu ul .node--type-document.node--view-mode-teaser .files .download .back:before {
          color: #222222; }
      .node--type-document.node--view-mode-teaser .files .download .icon-blue-download .path1:before {
        transition: color ease .8s; }
      .node--type-document.node--view-mode-teaser .files .download .icon-blue-download.green .path1:before, .node--type-document.node--view-mode-teaser .files .download #block-alertinformation .text-formatted a.icon-blue-download .path1:before, #block-alertinformation .text-formatted .node--type-document.node--view-mode-teaser .files .download a.icon-blue-download .path1:before {
        color: #77B800; }
      @media all and (-ms-high-contrast: none) {
        .node--type-document.node--view-mode-teaser .files .download .icon-blue-download {
          position: relative; }
          .node--type-document.node--view-mode-teaser .files .download .icon-blue-download .path2, .node--type-document.node--view-mode-teaser .files .download .icon-blue-download .path3 {
            position: absolute;
            top: 0; } }

/**
*   Custom correction on bootstrap container-fluid
*/
.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  padding-left: 0;
  padding-right: 0; }

@media (max-width: 767.98px) {
  .container, #page-wrapper #main-wrapper #content, #block-views-block-homepage-document-list-block-1 .content > div .view-homepage-document-list, #page-wrapper #page footer.site-footer .region-footer #block-footer .text-formatted, #custom-search-cepheid .container_wrapper .advanced .container-advanced, #wrapper_content_selector #edit-output {
    max-width: 100%; } }
