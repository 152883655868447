
/**
*   Document teaser view
*/

.node--type-document.node--view-mode-teaser{
    background:rgba($grey-extralight,.25);
    margin-bottom: 5px;
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 37px;

    @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
        position: relative;
    }

    &:after{
        flex:1;
    }
    .info{
        display:flex;
        flex-direction: column;
        flex: 0 0 60%;
        position:relative;

        @include media-breakpoint-down(xs) {
            flex: 0 0 100%;
        }

        button{
            position: absolute;
            left: -34px;
            border: none;
            top: 3px;
            background: transparent;
            &:before{
                @include font-size(18);
            }
        }
        .title{
            @include font-size(18);
            color:$black;
            margin-bottom: 5px;
            //font-family: $h; 
            font-weight:500;
            font-family: $font-semibold;
        }
        .categories{
            margin-bottom: 5px;
            .restricted{
                display: inline; 
                color:$white;
                background-color:$orange;
                @include font-size(12);
                //font-family: $h; 
                font-weight:500;
                font-family: $font-semibold;
                padding: 1px 6px;
                border-radius: 10px;
            }
            .field__label{ display:none; }
            
            & > div,
            .field__items{
                display: inline;
            }

            & > div > .field__item, 
            & > .field__item,
            .field__item{
                display: inline;
                color:$blue;
                background-color:rgba($blue,.1);
                @include font-size(12);
                //font-family: $h; 
                font-weight:500;
                font-family: $font-semibold;
                padding: 1px 6px;
                border-radius: 10px;
                white-space: nowrap;
            }
        }
        .reference{
            .ref{
                display:none;
                //font-family: $h; 
                font-weight:400;
                font-family: $font-regular;
                @include font-size(13);
                color:$black;
                transition: color ease .3s;
                &.green{
                    color: $green;
                }
                &.active{
                    display: inline-block;
                }
            }
        }
    }
    .languages{
        flex: 0 0 25%;
        align-self: center;
        //font-family: $h; 
        font-weight:500;
        font-family: $font-semibold;
        @include font-size(14);
        .version{
            padding: 0 0 3px 0;
            //font-family: $h; 
            font-weight:400;
            font-family: $font-regular;
            @include font-size(18);
        }
        label{
            display:block;
            //font-family: $h; 
            font-weight:400;
            font-family: $font-regular;
            @include font-size(14);
            margin: 0;
        }
        .select2-selection{
            border-bottom: 1px solid rgba($black, 0.8) !important;
            .select2-selection__rendered {
                //font-family: $h;
                font-weight:500;
                font-family: $font-semibold;
                @include font-size(14);
                padding-left: 0;
            }
            .select2-selection__arrow b {
                border-color: rgba($black, 0.8) transparent transparent transparent;
            }
        }
    }
    .files{
        flex: 0 0 15%;
        align-self: center;
        text-align: right;

        @include media-breakpoint-down(xs) {
            flex: 0 0 65%;
            position: absolute;
            right: 20px;
            bottom: 10px;
        }

        .icon-blue-video{
            padding-right: 0;
            background: transparent;
            border: none;
            @include font-size(37);

            @include media-breakpoint-down(xs) {
                @include font-size(33);
            }
        }
        .download{ 
            width:36px;
            height:36px;
            display:none;
            position: relative;
            margin-left: 12px;

            &:hover{
                text-decoration: none;
                opacity:0.95;
            }
            & > span{
                font-size: 37px;

                @include media-breakpoint-down(xs) {
                    @include font-size(33);
                }
            }
            &.active{
                display: inline-block;
            }
            .icon-back-website{
                font-size: 10px;
                position: absolute;
                right: -5px;
                display: none;
                &:before{
                    color: $grey-darkest;
                }
            }
            .icon-blue-download {
                .path1:before{
                    transition: color ease .8s;
                }
                &.green{
                    .path1:before{
                        color: $green;
                    }
                }
            }
            // Debug IE 11 for svg
            @media all and (-ms-high-contrast:none){
                .icon-blue-download {
                    position: relative;
                    .path2, .path3{
                        position: absolute;
                        top:0;
                    }
                } 
            }

        }
    }
    
}