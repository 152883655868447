/* HEADER DISPLAY */

$hamburger-padding-x           : 15px;
$hamburger-padding-y           : 15px;
$hamburger-layer-width         : 37px;
$hamburger-layer-height        : 3px;
$hamburger-layer-spacing       : 8px;
$hamburger-layer-color         : #FFF;
$hamburger-layer-border-radius : 4px;
$hamburger-hover-opacity       : 0.9; 
$hamburger-active-layer-color  : $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

// Hamburgers
@import "../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";






/**
*   Body not fixed
*/

body:not(.fixed) {
	#header-top {
		.cepheid-logo{
			margin: 40px 0 20px 0;

			img {
				/*@include media-breakpoint-down(lg) {
					position: absolute;
					left: 0;
					top: 60px;
					width: 180px;
				}*/
				// @include media-breakpoint-down(md) {
				// 	width: 110px;
				// 	left: -25px;
				// 	top: 93px;
				// }
			}
		}
	}
}


/**
*
* Main header
*
*/

#toolbar-administration {
	z-index: 1500;
}

#header{
	background: $blue;
	background: radial-gradient(circle, rgba(20,190,240,1) 0%, rgba($blue,1) 72%, rgba($blue,1) 97%);
	// transition: height ease .2s;
	transition: .6s;
	// Menu is in a modal
	#modalMenu {
		padding-right: 0;
		transition : opacity ease .5s;
		opacity:0;
		&.show {
			opacity:1;
		}
		.modal-dialog {
			height: 100%;
			margin: 0 0 0 auto;
			max-width: 405px;
			// Bug chrome windows : zindex is wrong if fixed
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1050;
		}
		.modal-content {
			background-color: $blue-dark;
			height: 100%;
			border-radius: 0;
			border:none;

		}
		#navigation{
			position: relative;
			.region-menu-top {
				@extend .d-flex, .flex-column;
				#block-cepheid-main-menu {
					ul {
						padding:120px 45px 30px;
						list-style-type: none !important;
						// Debug IE 11 (bullet remove)
						@media all and (-ms-high-contrast:none){
							//list-style-position:inside !important;
							li{
								height:60px;
								color:$blue;
							}
						}
						.nav-item {
							a {
								@extend .d-flex;
								border-top: 1px solid rgba($white,0.2);
								padding: 0.75rem 10px;
								@include font-size(18);
								color: rgba($white,1);
								line-height: 31px;
								@extend .transition;
								&:before {
									color:rgba($white,1);
									margin-right: 22px;
									@include font-size(28);
									@extend .my-auto;
									@extend .transition;
									opacity:.6;
								}
								&:hover {
									color: rgba($white,.6);
									&:before {
										color: rgba($white,.6);
									}
								}
							}
						}
						.logout {
							position: absolute;
							top: 0;
							border-top: none !important;
							@include font-size(13);
							@extend .icon-log-out;
							&:before {
								font-family: 'icon';
								
							}
						}
						.login{
							position: absolute;
							top: 0;
							border-top: none !important;
							@include font-size(13);
							@extend .icon-log-in;
							&:before {
								font-family: 'icon';
								
							}
						}
						.homepage {
							&:before {
								content:"";
								background:url(../images/homepage.png) no-repeat;
								width: 29px;
								height: 30px;
								padding: 0;
								background-size: 34px;
								background-position: -3px -3px;
							}
							&:hover{
								&:before {
									opacity: .4 !important;
								}
							}
						}
						.myaccount {
							@extend .icon-parametres;
							&:before {
								font-family: 'icon';
							}
						}
						.savedsearch {
							@extend .icon-manage-search;
							&:before {
								font-family: 'icon';
							}
						}
						.faq {
							@extend .icon-faq;
							&:before {
								font-family: 'icon';
							}
						}
						.contact {
							@extend .icon-mail;
							&:before {
								font-family: 'icon';
								font-size: 22px !important;
							}
						}
						.policy {
							@extend .icon-paper;
							&:before {
								font-family: 'icon';
							}
						}
						.back {
							@extend .icon-back-website;
							&:before {
								font-family: 'icon';
							}
						}
					}
				}
			}
			.navbar-brand {
				position: absolute;
			}
		}
	}

	// Logo
	#header-top{
		min-height: 100px;

		@include media-breakpoint-down(sm) {
			min-height: 80px;
		}

		& > .container {
			position: relative;
		}
		.cepheid-logo{
			margin: 40px 0 20px 0;
			position: absolute;
			img {
				// @include media-breakpoint-down(lg) {
				// 	position: absolute;
				// 	left: 0;
				// 	top: 60px;
				// 	width: 180px;
				// }
				// @include media-breakpoint-down(md) {
				// 	width: 110px;
				// 	left: -25px;
				// 	top: 93px;
				// }
				@include media-breakpoint-down(lg) {
					max-width: 180px;
				}
			}
		}
		.baseline{
			display: none;
			text-align: center;
			@extend .my-auto;
			@include font-size(30);
			//font-family: $h; 
			font-weight:100;
			font-family: $font-thin;
			color: $white;
			flex: 0 0 75%;
			margin-bottom: 20px !important;
			padding-right: 100px;
			@media screen and (max-width: 1400px){
				padding-right: 160px;
			}
			@include media-breakpoint-down(md) { display: none; }
			span{
				color: $white;
				display: block;
				@include font-size(25);
			}
			// Debug IE 11 (no margin "auto" on ie)
			@media all and (-ms-high-contrast:none){
				margin-top: 50px !important;
			}
		}
		.menu-toggle {
			@extend .d-flex;
			position: absolute;
			right: 0;
			top: 20px;
			color:$white;

			.hamburger {
				z-index: 1100;
			}
			.brand {
				@extend .my-auto;
				color:$white; 
				@include font-size(25);
				//font-family: $h; 
				font-weight:100;
				font-family: $font-thin;
				@include media-breakpoint-down(xl) {
					@include font-size(20);
				}
				@include media-breakpoint-down(md) {
					display:none;
				}
				// Debug IE 11
				@media all and (-ms-high-contrast:none){
					padding-top:10px;
				}
			}
		}
	}
	#header-bottom{
		// Search form
		.region-search{
			width: 100%;
			#block-documentsearchblockwsi{
				width: 100%;
			}
		}
	}
}
// Move close button when menu is opened
body.modal-open{
	#header #header-top .menu-toggle {
		position: fixed;
		right: 4px;
		top: 13px;
		z-index: 1060;
		.brand{
			display: none !important;
		}
	}
}

// Header without scroll
body:not(.fixed) {
	#header {
		/*@include media-breakpoint-down(lg) {
			padding-top: 30px;
		}*/
		
		.region-search {
			.wrapper_search {
				box-shadow: 0px 3px 20px #007AC2;

				@include media-breakpoint-down(sm) {
					margin: inherit;
					box-shadow:none !important;
				}
				fieldset {
					max-width: 100%;

					@include media-breakpoint-down(sm) {
						max-width: 100%;
					}
				}

				a {
					box-shadow: 0px 3px 20px #007AC2;
					@include media-breakpoint-down(sm) {
						box-shadow:none !important;
					}
				}
			}
		}

	}
	#header-top {
		@include media-breakpoint-down(sm) {
			/*width: 80%;*/
			margin: 0 auto;
			//height: 180px;
			& > .container{
				max-width: none;
			}
		}

		.baseline {
			@include media-breakpoint-down(lg) {
				width: 100%;
				margin: 0 auto;
				font-size: 25px;

				span {
					font-size: 20px;
				}
			}

			@include media-breakpoint-down(sm) {
				display: none !important;
			}

		}	

		.cepheid-logo{
			margin: 40px 0 20px 0;

			@include media-breakpoint-down(sm) {
				display: block;
				width: 100%;
				text-align: left;
				margin: 15px 0 10px 0 !important;
			}

			img {
				@include media-breakpoint-down(sm) {
					margin: 0 auto;
					max-width: 110px !important;
				}
			}
		}
	}
}

body.page-user-reset,
body.page-user-password,
body.page-user-register,
body.page-node-13:not(.fixed),
body.page-node-8:not(.fixed),
body.page-node-9:not(.fixed){
	@include media-breakpoint-up(sm) {
		#header-top{
				min-height: 190px !important; // Small header (for pages without the "search block" : 13,8,9)
		}
	}
}


.adminimal-admin-toolbar.toolbar-tray-open.toolbar-horizontal {
	#header{
		#modalMenu {
			.modal-content {
				margin-top: 79px;
			}
		}
	}
}

body.fixed{ 
	#page-wrapper{
		padding-top: 280px;
	}
	.menu-toggle {
		top: 10px !important;
	}
	#header{
		position: fixed;
		top: 0;
		height: 80px;
		z-index: 9;
		// Hide baseline when nav is fixed 
		.baseline, .brand{
			display:none !important;
		}
		#header-top .cepheid-logo {
			margin: 5px 0;
			img{
				height: 70px;
			}
		}
		.region-search{
			.advanced.active{
				position: relative;
				top: -106px;
				@include media-breakpoint-down(sm) {
					top: 0px;
				}
			}
			.wrapper_search{
				position: relative;
				top: -84px;


				@include media-breakpoint-down(sm) {
					position: static;
					margin: inherit;
					width: calc(100% - 120px) !important;
				}

				@include media-breakpoint-down(md) {
          			width: 40%;
        		}

				.form-item-input-search #edit-input-search {
					//font-family: $h; 
					font-weight:500;
					font-family: $font-semibold;
					height: 50px;

					&:focus {
						//font-family: $h; 
						font-weight:500;
						font-family: $font-semibold;
					}
				}
				& > a {
					width: 60px;
					height: 50px;
					line-height: 50px;
					
					.icon-filtre{
						font-size: 19px;
					}
				}
				.submiter{
					width: 60px;
					height: 50px;
					.submit{
						top: 13px;
						left: 17px;
					}
					#edit-submit-btn{
						width: 60px;
						height: 50px;
						line-height: 50px;
					}
				}
				
			}
		}
	}
}