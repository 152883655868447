
/**
*   Main search form (all pages)
*/

body {
  .select2-container{
    width: auto !important; // Remove JS width on select2
    [type="search"] {
        width: 100% !important; // Allow showing placeholder
    }
  }
  .select2-container--open {
    .selection {
        .select2-selection {
            border-bottom: 2px solid $blue-dark !important;
        }
    }
    .select2-search.select2-search--inline { 
        min-width: 310px;
    }
    .select2-dropdown {
      border: none;
      box-shadow: 0px 3px 6px #00000029;
      min-width: 130px;
      //min-height: 300px;
      .select2-results {
        & > ul.select2-results__options {
            padding: 10px;
            max-height: 300px;
            li.select2-results__option {
                background-color: $white;
                color: $black;
                //font-family: $h;
                font-weight:400;
                font-family: $font-regular;
                &:hover {
                    background-color: $blue-light;
                    color: $blue-dark;
                }
            }
        }
      }
    }
  }
  
  // Specific cas for the "Product" select2
  #select2-edit-produit-results{
    .select2-results__option {
      padding: 0px 6px;
      & > span{
        padding: 6px 0;
        display: inline-block;
      }
      .arrow{
        left: -7px;
        position:relative;
        width: 10px;
        display: inline-block;
        height: 10px;
        &:before{
          content:"";
          border-color: #888 transparent transparent transparent;
          border-style: solid;
          border-width: 5px 4px 0 4px;
          height: 0;
          left: 5px;
          margin-left: -4px;
          margin-top: -2px;
          position: absolute;
          top: 50%;
          width: 0;
        }
      }
      .level1{
        color:$black;
        padding-left:10px;
        
      }
      &.open{
        .level1 .arrow{
          &:before{
            transform: rotate(180deg);
          }
        }
      }
      .level2{
        display:none;
        padding-left:20px;
      }
      .level3{
        display:none;
        padding-left:25px;
      }
      .level4{
        display:none;
        padding-left:30px;
      }
    }
    
  }
}

#custom-search-cepheid{
    // Search vignettes
    .bootstrap-tagsinput{
      .tag{
        background:#888; 
      }
    }
  
    // Main search container
    .container_wrapper{
      display:flex;
      flex-direction: column;
      background-color: transparent;
      .wrapper_search{
        display: flex;
        width: 80%;
        max-width: 500px;
        margin: 0 auto 20px auto;

        @include media-breakpoint-down(sm) {
          width: calc(100% - 140px);
          margin: 0;
          box-shadow: none !important;
        }

        &.active{ 
          margin: 0 auto; 
        }
  
        // Input de recherche
        .form-item-input-search{
          padding: 0;
          margin: 0;
          background:$white;
          #edit-input-search{
            border-radius: 0;
            margin:0;
            height:70px;
            padding:10px 20px;
            border:none;
            border-bottom: none !important;
            background-image:none !important; // remove loader

            @include media-breakpoint-down(sm) {
              border-bottom: 1px solid grey;
              max-width: none;
            }
            
            &.ui-autocomplete-loading{
              background-image:url(../images/throbber-grey.png) !important;
            }
          }
          
        }
  
        // Bouton submit (1st)
        #edit-submit-btn{
          display: inline-block;
          width:70px;
          height:70px;
          flex: 0 0 auto;
          background-color: $blue;
          line-height: 70px;
          text-align: center;
          border:none;
          border-radius: 0;
        }
        
        .submiter{
          position:relative;
          width:70px;
          height:70px;
          .submit{
            color: #fff;
            font-size: 27px;
            pointer-events: none;
            position: absolute;
            top: 20px;
            left: 20px;
          }
          .ajax-progress{
            position: absolute;
            top: 30px;
            right: -30px;
            width: 30px;
            height: 30px;
            @include media-breakpoint-down(sm) {
              position: absolute;
              top: 23px; 
              right: 17px;
            }
            .throbber{
              width: 15px;
              height: 15px;
              display: block;
              background: transparent url(../images/throbber.png) no-repeat 0 center;
            }
          }
        }
  
        // Bouton advanced
        & > a{
          display: inline-block;
          width:70px;
          height:70px;
          flex: 0 0 auto;
          background-color: $grey-medium;
          line-height: 70px;
          text-align: center;
          font-size: 27px; 
          border:none;
          &:hover{
            text-decoration: none;
          }
          .icon-filtre{
            font-size: 22px; 
          } 
          .icon-fleche{
            display: none !important;
          }
          &.active{
            background-color: $blue-light;
            .icon-filtre{ display: none; }
            .icon-fleche{
              display: inline-block !important;
              font-size: 19px;
            }
          }
        }
      }
  
      // Advanced options (categories, dates...)
      .advanced{
        padding: 20px 0;
        display:flex;
        background:$blue-light;
        height:auto;
        opacity:1;
        // set hidden by default
        transition:all ease .4s;
        &:not(.active){
          height: 0;
          opacity:0;
          padding: 0;
          overflow: hidden;
          pointer-events: none;
        }
        // All labels & inputs
        label{
          visibility: hidden;
          display: block;
          @include font-size(13);
          margin: 5px 0 0 5px;
          color:$blue;
          &.selected{ visibility: visible; }
        }
        // All Selects
        .select2{
          display: block;

          @include media-breakpoint-down(md) {
                width: 100% !important;
          }

          &.select2-container--open{
            & ~ label{
              visibility: visible;
            }
          }
          .select2-selection{
            border:none;
            border-radius: 0;
            border-bottom:2px solid rgba($grey-dark,.6);
          }
          // Vignettes select2
          .select2-selection__choice{
            border-radius: 30px;
            background: $white;
            @include font-size(14);
            border:none;
            color:$blue;
            //font-family: $h; 
            font-weight:500;
            font-family: $font-semibold;
            .select2-selection__choice__remove{
              text-align: center;
              line-height: 17px;
              background:rgba($grey-dark,.6);
              width:18px;
              height:18px;
              float: right;
              color:$white;
              border-radius: 18px;
              margin: 2px 0 0 3px;
            }
          }
        }
        .container-advanced{
          @extend .container;
          display:flex;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }

          .wrapper_categories, .wrapper_dates {
            padding-top: 4px;
            display:flex;
            flex-direction: column;
            flex: 0 1 43%;
            margin-right: 2%;
            .dropdown-wrapper{
              position: absolute;
              top: 20px;
              right: 8px;
              pointer-events: none;
              border-color: #888 transparent transparent transparent;
              border-style: solid;
              border-width: 5px 4px 0 4px;
              height: 0;
              margin-left: -4px;
              margin-top: -2px;
              position: absolute;
              width: 0;
            }
          }
          .wrapper_dates{
            flex: 0 1 27%;
            margin-right: 2%;
            display:flex;
            flex-direction: column;
            .dates{
              display:flex;
              fieldset{
                flex: 0 0 49%;
                position: relative;
                margin: 0 0 16px 0;
                // Debug IE 11 flex
                @media all and (-ms-high-contrast:none){
                  max-width: 49%;
                }
                &:first-child{
                  margin-right: 2%;
                }
                input{
                  @include font-size(14);
                  padding: 0;
                  height: 26px;
                  margin-top: 12px;
                }
                // Icons on date
                @extend .icon-calendar;
                &:before{
                  font-family: 'icon';
                  position: absolute;
                  top: 26px;
                  right: 35px;
                  font-size: 22px;
                  color:rgba($black,.6);
                  pointer-events: none;
                }
                &.selected{
                  &:before{
                    color:$blue;
                  }
                }
              }
            }
          }
          .wrapper_submit{
            flex: 0 1 23%;
            display:flex;
            flex-direction: column;
            .ajax-progress{
              display:none;
            }
            .form-item-saved-search{
              position: relative;
              // Icons
              @extend .icon-manage-search;
              .select2-container{
                padding-top: 10px;
              }
              &:before{ 
                font-family: 'icon';
                position: absolute;
                top: 29px;
                left: 16px;
                font-size: 19px;
                color:rgba($black,.6);
                pointer-events: none;
              }
              .select2-selection__rendered {
                padding-left: 35px;
                @include font-size(16);
                line-height: 24px;
                font-weight: 400;
              }
              .select2-selection__clear{ display: none; }
            }
            #edit-submit-btn2{
              max-width: 240px;
              margin-left: 10px;
              margin-top: 22px;
              //font-family: $h; 
              font-weight:500;
              font-family: $font-semibold;
              border:none;
              border-radius: 5px;
              text-align: center;
              color:$white;
              @include font-size(16);
              background-color: $blue;
              background:url('../images/search_input.png') $blue no-repeat 28px 22px;
              background-position: 31.5% 8px;
              @include media-breakpoint-down(xl) {
                background-position: 20% 8px;
              }
              @include media-breakpoint-down(md) {
                width: 300px;
                margin: 34px auto 0 auto;
              }
              @include media-breakpoint-down(xs) {
                width: 250px;
                height: 38px;
              }
              &[disabled]{
                background-color: $grey-light;
                background-image:url(../images/throbber-grey.png) !important;
              }
            }
          }
        }
        
      }
      
    }
  
  }
  
/**
*   Main search result document list (all pages)
*/

#wrapper_content_selector{
  margin-bottom:40px;
  #content_selector {
    @include media-breakpoint-down(xs) {
      display: flex;
      flex-direction: column;
    }
  }

  #edit-output{
    @extend .container;
    padding: 0;
    // title, categories & save button
    & > .wrapper{
      display: flex;
      justify-content: space-between;
      margin: 0 0 20px 0;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        text-align: center;
      }
      .title{
        @include font-size(35);
        margin-top: 50px;
        color:$grey-darkest;
        @include media-breakpoint-down(md) {
          @include font-size(30);
          margin-top: 30px;
        }
      }
      .categories{
        & > span:not(.restricted){
          background-color: rgba($grey-medium,.2);
          margin-right: 10px;
          @include font-size(13);
          border-radius: 10px;
          padding: 2px 10px;
          //font-family: $h; 
          font-weight:500;
          font-family: $font-semibold;
          @include media-breakpoint-down(sm) {
            margin-bottom: 10px;
            display: inline-block;
          }
        }
        .reset{
          text-decoration: underline;
          @include font-size(13);
          color: $grey-dark;
        }
        @include media-breakpoint-down(sm) {
          padding: 0 10px 20px 10px;
          display: inline-block;
        }
      }
      .save-wrapper{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .save_this_search{
          line-height: 28px;
          &:hover{
            text-decoration: none;
          }
          span{
            margin-right: 10px;
          }
        }
      }
    }
  }
}
// Add padding to search result, when advanced search is open while scrolling
body.advanced_form_open.fixed{
  #wrapper_content_selector{
    @include media-breakpoint-up(md) {
      padding-top: 220px;
    }
  }
}
// Apercu
.apercu_save{
  & > div{
    label{
      color:$blue;
      display:block;
      @include font-size(12);
    }
  }
}

// Pager / pagination
#content_selector{
  .pager{
    @include font-size(18);
    text-align: center;
    margin: 40px auto;
    .progress{
      margin: 10px auto 0 auto;
      overflow: hidden;
      border-radius: 8px;
      height:8px;
      width:300px;
      background-color: $grey-medium;
      .percent{
        transition:all ease 2.5s;
        background-color: $blue;
        height:8px;
      }
    }
    button{
      margin-top:20px;
    }
  }
}

// Autocomplete on main search input
body > .ui-menu{
  padding: 10px 0;
  max-width: 500px;
  max-height: 500px;
  overflow-y: auto;
  margin: 0 10px;
  border: none !important;
  border-top: 1px solid #f1f1f1 !important;
  box-shadow: 0px 4px 5px rgba(0,0,0,.1);
  li.ui-menu-item{
    margin: 0 20px;
    padding: 5px 10px;
    max-height: 400px;
    overflow: auto;
    a{
      //font-family: $h; 
      font-weight:500;
      font-family: $font-semibold;
      color: $grey-darkest;
      border:none !important;
      background:none !important;
    }
    &:hover{
      background-color: rgba($blue,.2);
      a{ color: $blue; }
    }
  }
}

body.fixed{
  .container_wrapper .advanced{
    background-color: rgba($blue-light,.96) !important;
  }
}