body {
	//font-family: $h; 
	font-weight:100;
	font-family: $font-thin;
	color:$blue-dark;
	background-color: $white;
	overflow-x: hidden;
	min-height: 100%;
	&.menu-open {
	  overflow: hidden;
	}
  }
  body *{
	font-weight: 400; // Firefox debug graisse 
	color:$grey-dark;
	//min-height: 100vh;
	a{
	  color:$blue;
	}
  }
  
  .transition {
	transition: all 0.3s ease-out;
  }
  
  
  /**
  *   Main content (all pages)
  */
  
  #page-wrapper{
	// #header : @see header.scss
	#main{
	  & > div{
		display: block; // IE11 debug 
	  }
	}
	#main-wrapper{
	  //  Main container
	  #content{
		background-color: $white;
		@extend .container;
	  }
	  @include media-breakpoint-down(md) {
		padding-right: 15px;
		padding-left: 15px;
	  }
	}
  }
  
  .back-to-top{
	position: fixed;
	bottom: 100px;
	right: 20px;
	display: none;
	cursor: pointer;
	span{
	  font-size: 40px;
	}
  
	@include media-breakpoint-down(xs) {
	  position: inherit;
	  left: 0;
	  text-align: center;
	  top: -18px;
	}
  }
  
  /**
  *   Links, titles, alertes, forms and buttons
  */
  h1,h2,h3{
	//font-family: $h !important; 
	font-weight: 100;
	font-family: $font-thin !important;
  }
  .btn{
	background-color: $blue;
	color: $white;
	padding:10px 20px;
	border:1px solid $blue;
	border-radius: 3px;
	min-height: 50px;
	&.white{
	  background-color: $white;
	  color: $blue;
	  border-color:#ccc;
	}
	&.blue{
	  background-color: $blue;
	  color: $white;
	  border-color:$blue;
	}
	&.active, &.green{
	  background-color: $green;
	  border-color: $green;
	  color: $white;
	}
  }
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="date"]{
	border: none !important;
	border-bottom: 2px solid rgba($black,.5) !important;
	background-color: transparent !important;
	border-radius: 0 !important;
	@include font-size(16);
	&:focus{
	  border-color: $blue !important;
	  //font-family: $h; 
	  font-weight:500;
	  font-family: $font-semibold;
	  color:$blue;
	  outline: none;
	  box-shadow: none;
	}
  }
  input[type=date]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	display: none;
  }
  input[type=date]::-webkit-calendar-picker-indicator {
	opacity:0;
	cursor: pointer;
	width: 20px;
	height: 20px;
  }
  input[type=checkbox]{
	@extend .custom_checkbox;
  }
  textarea{
	border:none !important;
  }
  // Default select2 design
  .select2-selection{ 
	border: none !important;
	border-bottom: 2px solid rgba($black,.5) !important;
	background-color: transparent !important;
	border-radius: 0 !important;
	.select2-selection__rendered{
	  @include font-size(14);
	  //font-family: $h; 
	  font-weight: 500;
	  font-family: $font-semibold;
	}
	// position "clear" (x)
	.select2-selection__clear {
	  position: absolute;
	  right: 10px;
	  top: 4px;
		text-align: center;
		line-height: 17px;
		background:rgba($grey-dark,.6);
		width:18px;
		height:18px;
		color:$white;
		border-radius: 18px;
		margin: 2px 0 0 3px;
	}
  }
  // Vignettes select2
  .select2{
	.select2-selection__choice{
	  border-radius: 30px !important;
	  background-color: $white !important;
	  @include font-size(14);
	  border:none !important;
	  color:$blue;
	  //font-family: $h; 
	  font-weight:500;
	  font-family: $font-semibold;
	  .select2-selection__choice__remove{
		text-align: center;
		line-height: 17px;
		background:rgba($grey-dark,.6);
		width:18px;
		height:18px;
		float: right;
		color:$white;
		border-radius: 18px;
		margin: 2px 0 0 3px;
	  }
	}
  }
  .selection:focus, .select2-selection:focus{
	outline: none !important;
  }
  input::placeholder, .select2-selection__placeholder {
	color: $grey-darkest !important;
	opacity:1 !important;
	@include font-size(16); 
  }
  // Page H2/H3
  body.node--type-page{
	h1{
	  @include font-size(35);
	  padding:40px 0 20px 0;
	}
	.node__content{
	  .field--name-body{
		max-width: 980px; // Max width of any texts (for large screen)
		& > h2:first-child{
		  @include font-size(24);
		  padding:0 0 20px 0;
		}
	  }
	}
  }
  .alert-wrapper{
	position: fixed;
	width: 100%;
	text-align: center;
	top: 40%;
	left: 0;
	z-index: 10;
  
	@include media-breakpoint-down(xs) {
	  width: 75%;
	  right: 0;
	  margin: 0 auto;
	}
  
	.alert{
	  @extend .offset-sm-4, .col-sm-4;
	  background-color: #FDB71A;
	  color:$white;
	  padding: 30px;
	  border-radius: 5px;
	  position: relative;
	  @include font-size(16);
	  //font-family: $h; 
	  font-weight:500;
	  font-family: $font-semibold;
  
	  @include media-breakpoint-down(xs) {
		padding: 20px;
	  }
  
	  button.close, button.btn-close{
		position: absolute; 	
		top: 0;
		right: 0;
		&:before{
		  color: $white !important;
		}
	  }
	}
  }
  .tooltip{
	&.show {
	  opacity: 1;
	}
	.arrow{
	  &:before{
		border-top-color:$white;
	  }
	}
	.tooltip-inner{
	  background-color: $white;
	  color: $grey-darkest;
	}
  }
  
  /**
  *   Webforms (can be linked to any pages)
  */
  body.node--type-page{
	.node__content{
	  .field--name-field-form{
		background-color: rgba($grey-bg, .2);
		max-width:980px;
		margin: 40px 0;
		padding: 40px 70px;
  
		@include media-breakpoint-down(sm) {
		  padding: 40px 50px;
		}
  
		form#webform-submission-contact-node-8-add-form {
		  display: flex;
		  flex-direction: column;
  
		  #edit-flexbox-02 {
			padding-right: 0;
			width: 50%;
  
			@include media-breakpoint-down(sm) {
			  width: 100%;
			  padding-right: 15px;
			}
		  }
		  #edit-actions {
			order: 8;
		  }
		  .captcha {
			order: 7;
			margin: 0 auto 20px auto;
  
			.g-recaptcha {
			  iframe {
				@media screen and (max-width: 430px) {
				  margin-left: -30px;
				}
			  }
			}
		  }
		}
  
		form#webform-submission-create-an-account-node-13-add-form {
		  display: flex;
		  flex-direction: column;
			  
		  #edit-actions {
			order: 8;
		  }
		  .captcha {
			order: 7;
			margin: 0 auto 20px auto;
  
			.g-recaptcha {
			  iframe {
				@media screen and (max-width: 430px) {
				  margin-left: -30px;
				}
			  }
			}
		  }
		}
  
		[id^='edit-processed-text']{
		  h2{
			padding-left: 25px;
			margin-bottom: 15px;
			@extend .icon-green-profile;
			&:before{
			  font-family: 'icon';
			  @include font-size(43);
			  position: absolute;
			  left: -25px;
			  top: -12px;
			}
		  }
		}
		#edit-processed-text-01{
		 h2{
		  margin-bottom: 15px !important;
		  @extend .icon-green-message;
		 }
		}
		#edit-flexbox, #edit-flexbox-01, #edit-flexbox-02, #edit-flexbox-03 {
			  @include media-breakpoint-down(sm) {
				  padding-right: 0 !important;
				  padding-left: 0 !important;
			  }
			  fieldset {
				  @include media-breakpoint-down(sm) {
					  padding-right: 0 !important;
					  padding-left: 0 !important;
				  }
			  }
		}
  
		#edit-flexbox-03 {
		  .fieldset-wrapper {
			fieldset {
			  &:first-child {
				width: 50%;
  
				@include media-breakpoint-down(sm) {
				  width: 100%;
				  padding-right: 15px;
				}  
			  }
			  
			}
		  }
		}
  
		.form-group {
		  margin-bottom: 22px;
		  position: relative;
		}
		.field-suffix{
		  position: absolute;
		  top: 13px;
		  right: 30px;
		  .webform-element-help{
			border: none;
			background: transparent;
			span{
			  display: none;
			}
			&:before{
			  content:"";
			  background:url(../images/help.png) no-repeat;
			  position: absolute;
			  left: -4px;
			  top: 1px;
			  width: 20px;
			  height: 20px;
			}
		  }
		  
		}
		div.js-form-type-checkbox{
		  margin: 50px 30px;
		  padding: 20px 40px;
		  border-top: 1px solid rgba($black,.1);
		  border-bottom: 1px solid rgba($black,.1);
		  //font-family: $h; 
		  font-weight:400;
		  font-family: $font-regular;
  
		  @include media-breakpoint-down(sm) {
			margin: 50px 0px;
			padding: 20px 30px;
		  }
		  @include media-breakpoint-down(xs) {
			padding: 20px 0px;
  
		  }
		}
		input.btn{
		  width: auto;
		  margin: 0 auto;
		  padding: 10px 40px;
		}
	  }
	}
  }
  /**
  *   Update popup modal
  */
  #popup[data-type="update"] {
	.modal-header {
	  h5 {
		position: relative;
  
		&:before {
		  position: absolute;
		  font-family: 'icon' !important;
		  @extend .icon-manage-search;
		  color: $green;
		  font-size: 25px;
		  right: 0;
		  left: 0;
		  bottom: 30px;
		}
	  }
	}
	.modal-body {
	  border-bottom: 1px solid #BEC0BD;
	  margin-bottom: 20px;
	}
  }
  
  
  /**
  *   Delete popup modal
  */
  #popup[data-type="delete"] {
	.modal-footer {
  
	}
  }
  
  /**
  *   Common popup modal
  */
  .modal-open .modal:not(#modalMenu) {
	text-align: center;
	.modal-dialog {
	  max-width: 890px;
  
	  @include media-breakpoint-down(xs) {
		margin-top: 60px;
	  }
	}
	.modal-title{
	  width: 100%;
	  //font-family: $h;
	  font-weight:500;
	  font-family: $font-semibold;
	  @include font-size(20);
	  color:$black;
	  //font-family: $h; 
	  font-weight:700;
	  font-family: $font-bold;
	}
	.modal-header{
	  h5 {
		  //font-family: $h; 
		  font-weight:500;
		  font-family: $font-semibold;
		  margin-top: 15px;
	  }
	  
	  border: none;
	}
	.modal-body {
	  padding: 0 0 20px 0;
  
	  @include media-breakpoint-down(xs) {
		padding: 0;
	  }
  
	  p {
		span {
		  //font-family: $h; 
		  font-weight:500;
		  font-family: $font-semibold;
		}
	  }
	}
	.close, .btn-close{
	  @extend .icon-close-modale;
	  border: none;
	  background: transparent;
	  position: absolute;
	  right: 30px;
	  top: 20px;
	  span{ display: none; }
	  &:before{
		font-family: 'icon';
		@include font-size(22);
		color:$grey-dark;
	  }
	}
	.modal-content{
	  // padding: 20px;
	  padding: 50px 40px;
  
	  @include media-breakpoint-down(xs) {
		padding: 20px 40px;
	  }
	}
	.modal-footer{
	  justify-content: center;
	  border-top:none;
	  //font-family: $h; 
	  font-weight:400;
	  font-family: $font-regular;
	  .btn-secondary{
		background:$white;
		border-color: rgba($grey-dark,.6);
		color: $grey-btn-modal;
	  }
	}
	form{
	  background:rgba($grey-extralight,.25);
	  margin-top: 20px;
	  padding: 50px 20%;
	  input[name="search_name"]{
		width: 100%;
	  }
	}
	.apercu_save{
	  padding: 0 50px;
	  margin-top: 40px;
	  text-align: left;
	  justify-content: space-between;
	  @include media-breakpoint-down(sm) {
		flex-direction: column;
		& > div{
		  margin: 5px 15px;
		}
	  }
	  & > div{
		margin: 0 5px;
		//font-family: $h; 
		font-weight:500;
		font-family: $font-semibold;
		max-width: 330px;
		label{
		  //font-family: $h; 
		  font-weight:400;
		  font-family: $font-regular;
		}
	  }
	}
	&.video-modal{
	  z-index: 2060; // IE11
	  .modal-content{
		background-color: transparent;
		border: none;
	  }
	  .modal-body{
		border-radius: 5px;
		background-color: $grey-darkest;
		video{
		  width:100%;
		}
	  }
	  .modal-footer{
		text-align: left;
		padding: 0;
		.modal-title{
		  color: $white;
		  @include font-size(16);
		  //font-family: $h; 
		  font-weight:500;
		  font-family: $font-semibold;
		}
	  }
	  button.close, button.btn-close{
		padding: 0;
		position: relative;
		left: 40px;
		@include font-size(40);
		color: $white;
		&:before{
		  color: $white;
		  @include font-size(30);
		}
	  }
	}
  }
  .modal-backdrop {
	z-index: 2;
  }
  
  /**
  *   Homepage
  */
  .path-frontpage{
	#header{
	  #header-top {
		.cepheid-logo {
		  position: relative !important; // different size of header
		}
	  }
	  .baseline{
		display: inline-block !important; 
	  }
	}
	#block-cepheid-content{ 
	  display:none;
	}
  }
  #block-alertinformation{
	background:$grey-extralight;
	padding:20px;
	margin-top: 30px;
	@extend .offset-xxl-7, .col-xxl-5, .offset-lg-6, .col-lg-6, .offset-md-2, .col-md-8;
	@include media-breakpoint-down(md) {
	  margin-top: 30px;
	  margin-bottom:50px; // "New document" est décalé
	}
	@include media-breakpoint-down(xs) {
	  order: 2;
	  margin-top: 50px;
	  margin-bottom: 0;
	}
  
	.text-formatted{
	  color: $grey-dark;
	  display:flex;
	  justify-content: center;
	  align-items: center;
	  justify-content: space-around;
	  @include media-breakpoint-down(xs) {
		  flex-direction: column;
	  }
  
	  p { 
		  margin-bottom: 0; 
	  }
  
	  p:first-child{
			padding-left:50px;
			position: relative;
			flex: 0 0 49%;
			@extend .icon-green-subscribe;
  
		  @include media-breakpoint-down(xs) {
			  margin-bottom: 25px;
			  padding-top: 12px;
		  }
  
			&:before{
			  position: absolute;
			  left: -10px;
			  font-family: 'icon';
			  width: 34px;
			  height: 45px;
			  top: -9px;
			  @include font-size(45);
			}
	  }
	  a{
		@extend .icon-parametres;
		@extend .btn, .green;
		&:before{
		  font-family: 'icon';
		  @include font-size(16);
		  color: $white;
		  margin-right: 10px;
		}
		&:hover{
		  text-decoration: none;
		}
		// Debug IE 11
		@media all and (-ms-high-contrast:none){
		  display: block;
		}
	  }
	}
  }
  #block-views-block-homepage-document-list-block-1{
	position: relative;
	top: -30px;
  
	@include media-breakpoint-down(xs) {
	  top: 20px;
	}
  
	h2{
	  //font-family:$h;
	  font-family: $font-regular;
	  font-weight: 100;
	  @include font-size(35);
	  position:relative;
	  padding: 0 0 5px 0;
	  color:$grey-darkest;
	  margin-bottom: 40px;
	  &:after{
		left: 0;
		position: absolute;
		bottom:0;
		height:1px;
		width:45px;
		content:"";
		background:$grey-dark;
	  }
	}
	.content{
	  > div{
		margin: 0;
		padding: 0;
		.view-homepage-document-list{
		  @extend .container;
		  margin: 0;
		  padding: 0;
		  width: 100%;
		  .view-content{
			padding: 0;
			align-items: flex-start;
			margin: 0;
			flex-direction: column;
			width:100%;
			.views-row{
			  display: flex;
			  width: 100%;
			  & > article{
				width:100%;
				height: 100%;
			  }
			}
		  }
		}
	  }
	}
  }
  
  /**
  *   Alertes
  */
  .messages__wrapper{
	.messages	{
	  .close, .btn-close{
		@extend .icon-close-modale;
		border: none;
		background: transparent;
		span{ display: none; }
		&:before{
		  font-family: 'icon';
		  @include font-size(22);
		  color:$grey-dark;
		}
	  }
	}
  }
  
  /**
  *   Page Not found
  */
  body.title-page-not-found{
	#block-cepheid-content{
	  & > div.content{
		padding: 60px 10px;
		text-align: center;
	  }
	}
  }