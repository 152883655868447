/**
*
*   Rangées wysiwyg (conteneurs et éléments)
*
*/


/**
*   Liste des éléments
*/
[class^="conteneur_"]{
	padding-left: 0;
	padding-right: 0;
	p{
		margin-bottom: 10px;
		strong{
			color:$orange;
		}
	}
	.embedded-entity{
		img{
			width:100%;
		}
	} 
	h2{
		@include font-size(28);
		//font-family: $h !important; 
		font-weight: 400;
		font-family: $font-regular !important;
	}
	h3{
		@include font-size(23);
		color: $blue;
		//font-family: $h !important; 
		font-weight: 400;
		font-family: $font-regular !important;
		b, strong{
			//font-family: $h; 
			font-weight:500;
			font-family: $font-semibold;
			color: $black;
		}
	}
	ul {
		list-style: none !important; /* Remove default bullets */
		li{
			padding-bottom: 5px;
			&:before {
				content: "\2022"; 
				color: $blue;
				font-weight: bold;
				display: inline-block;
				width: 1em;
				margin-left: -1em;
			}
			.ck-list-bogus-paragraph {
				display:  inline !important;
			}
		}
		
	}
	
}


.element_cta_gris{
	background:rgba($black,.07);
	padding:60px 40px 40px 40px;
	position:relative;
	& > br{ display: none; }
	@include media-breakpoint-down(md) {
		margin-bottom:10px;
	}
	&.with_photo{
		padding:40px 40px 20px 40px;
		position:relative;
		@include media-breakpoint-down(md) {
			margin-bottom:10px;
		}
	}
	img{
		position:absolute;
		top:25px;
		right:25px;
		width:43px;
		height:43px;
		@include media-breakpoint-down(md) {
			top: 20px;
			right: 20px;
			width: 33px;
			height: 33px;
		}
	}
	.embedded-entity,
	.cke_widget_image{
		position:absolute;
		top:25px;
		right:25px;
		width:43px;
		height:43px;
		img{
			width:100%;
		}
	}
	div.photo{
		position:absolute;
		top:-20px;
		right: 30px;
	}
	h2{
		@include font-size(24);
	}
	& > a{
		min-width: 110px;
	}
}

.element_citation{
	padding: 30px 20px;
	p:first-child{
		position: relative;
		display:block;
		@include font-size(25);
		&:before{
			position: absolute;
			top:0;
			left:-20px;
			content:"";
			width:47px;
			height:42px;
			background:url('/themes/custom/cepheid/images/quote.png') no-repeat;
			background-size: 47px 42px;
		}
	}
	span{
		display:block;
		color:$black;
		position: relative;
		@include font-size(14);
		padding-top:20px;
		&:after{
			position: absolute;
			top:0;
			left:0; 
			border-top:1px dotted rgba($blue,.4);
			content:"";
			width:70px;
			height:1px;
		}
	}
}


/**
*   Liste des conteneurs
*/

div[class^="conteneur_"]{
	margin-top:20px;
	margin-bottom:20px;
	&:first-child {
		margin-top: 0;
	}
}

.conteneur_full{
	// Comportement de certains éléments et cas particuliers
	.element_cta_gris{
		padding: 60px 40px 40px 40px;
		text-align: center;
		& > img,
		& > .embedded-entity,
		.cke_widget_image{
			left:50%;
			margin-left:-23px;
			top:45px;
		}
		h2{
			margin-top: 50px;
		}
		.btn{
			margin-top: 20px;
		}
	}
	.element_cta_bleu{
		padding: 60px 40px 60px 40px;
		text-align: center;
		h2{
			margin-top: 10px;
		}
		.btn{
			margin-top: 20px;
		}
	}
}
.conteneur_1_col > div{
	width:100%; 
}

.conteneur_1_col,
.conteneur_2_col,
.conteneur_3_col,
.conteneur_6_col{
	margin-left: auto;
	margin-right: auto;
}

/**
*   Style apparent dans l'éditeur wysiwyg
*/

.media-left {
	padding-left: 0;
	@include media-breakpoint-down(md) {
		padding-right:0;
	}
	.media {
		padding-left: 0;
		@include media-breakpoint-down(md) {
			padding-right:0;
		}
		.embedded-entity,p, img {
			width: 100%;
			img {
				@extend .img-fluid;
			}
		}
	} 
	.text {
		padding-right: 6%;
		padding-left: 5%;
		@extend .my-auto;
	}
}
.media-right {
	padding-right: 0;
	.media {
		padding-right: 0;
		.embedded-entity,p, img {
			width: 100%;
			img {
				@extend .img-fluid;
			}
		}
	} 
	.text {
		padding-left: 6%;
		padding-right: 5%;
		@extend .my-auto;
	}
}
body.cke_editable,
.ck-editor__editable {
	background: #ffffff !important;
	* {
		animation-name: none !important;
	}
	[class^="conteneur_"]{
		width:98%;
		margin:10px 10px 20px 10px;
		padding:5px;
		border:2px dashed #999;
		min-height:50px;
		max-width: none !important;
		& > div{
			border:2px dashed #ccc;
			min-height:50px;
		}
	}
	[class^="element_"]{
		min-height:50px;
		margin:5px;
	}
}
