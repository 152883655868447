// Custom colors
$blue: #007AC3;
$blue-dark: #007AC2;
$blue-dark-opacity-15: rgba($blue-dark, 0.15);
$blue-dark-opacity-30: rgba($blue-dark, 0.3);
$blue-dark-opacity-40: rgba($blue-dark, 0.4);
$blue-dark-opacity-50: rgba($blue-dark, 0.5);
$blue-light: #E5F1F9; // Breadcrumb bg
$blue-lighter: #C5E9F8; // Search bg
$orange-dark: #FF6C3D;
$orange-dark-opacity: rgba($orange-dark, 0.1);
$orange-dark-opacity-15: rgba($orange-dark, 0.15);
$orange-dark-opacity-30: rgba($orange-dark, 0.3);
$orange-light: #F4921F;
$orange-lighter: #FFEFE5;
$green: #77B800;
$grey-bg : #F5F6F5;

// Typo colors
$grey-darkest: #222222;
$grey-dark: #4D4D4F;
$grey-medium: #D7D8D6;
$grey-light: #A1A1A2;
$grey-btn-modal: #707070;
$grey-extralight: #ECEDEC;

// Default colors
$white: #ffffff;
$black: #000000;

// Transition to extend
.transition {
	transition: all 0.3s ease-in-out;
}

// Bootstrap Overrides
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px
	) !default;

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1320px
	) !default;
